import React from 'react';

const Home = () => {
    return ( 
            <div className="home">
                <h1 className="homeTitle">Bienvenido</h1>
                <h2 className="homeSubT">Seleccione una opción para visualizar la información</h2>
            </div>
     );
}
 
export default Home;