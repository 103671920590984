import React, {useContext, Fragment, useState, useEffect} from 'react';
import CharterContext from '../../context/schoolCharter/CharterContext';
import AcademyContext from '../../context/academies/AcademyContext';
import Select from 'react-select';
//From here Material UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const CharterTable = () => {

    const charterContext = useContext(CharterContext);
    const {charters, setCharterSelected, charterSelected} = charterContext;

    const academyContext = useContext(AcademyContext);
    const {academySelected} = academyContext;

    const [yearSelected, setYearSelected] = useState('Seleccione un año');  

    const [currentCharters, setcurrentCharters] = useState([]);

    useEffect(() => {

        if(academySelected._id) {
            let filteredCharters = charters.map((eachCharter) => {
                if (eachCharter._idAcademy === academySelected._id) {
                    return eachCharter;
                }
                return undefined;
            });
            filteredCharters = filteredCharters.filter((eachCharter)=>{
                return eachCharter !== undefined;
            })
            setcurrentCharters(filteredCharters);
        }
    }, [charters, academySelected, setcurrentCharters]);

    const getRowClass = (id) => {

        let rowClass = "mousePointer";

        //let currentDate = new Date();

        if (charterSelected._id === id) {
            return rowClass + " rowSelected";    
        } 

       /*  if (currentDate > expDate && !isClosed) {
            return rowClass + " expiredRow";
        } 

        currentDate.setMonth(currentDate.getMonth() + 1);
        
        if (currentDate > expDate && !isClosed) {
            return rowClass + " warningRow";
        } */

        return rowClass;
    }
    
    const renderCharters = ()=> {

        if(!currentCharters || currentCharters.length === 0) {

            return <tr><td>Nada para mostrar</td></tr>  
       
        } else {
            
            let rowsToPrint = currentCharters.map((eachCharter)=>{

                if(eachCharter._exp.getFullYear() === Number.parseInt(yearSelected)){

                        let isClosed = "No";
                        if(eachCharter._closed) {
                            isClosed = "Si"; 
                        } 

                        return (
                            <StyledTableRow 
                                key={eachCharter._id} 
                                onClick={()=>setCharterSelected(eachCharter)}
                                className={getRowClass(eachCharter._id)}
                            >
                                <StyledTableCell>
                                    {eachCharter._amount}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {eachCharter._date.toLocaleDateString()}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isClosed}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {eachCharter._exp.toLocaleDateString()}
                                </StyledTableCell>
                            </StyledTableRow>
                        )
                } 
                return null;
            });
            return rowsToPrint;
        }    
    }

    const yearOptions = () => {

        const yearsObj = {};

        const years = currentCharters.map((eachCharter)=> {

            const year = eachCharter._exp.getFullYear();

            if(!yearsObj[year]){
                yearsObj[year] = year;
                return Number.parseInt(year);    
            }
            return undefined;
        }).filter(eachYear => eachYear !== undefined);
        years.sort();
        const options = years.map((eachYear)=>{
            return {
                value: eachYear, label: eachYear
            }   
        });
        return options;
    }

     //From here I used Material UI

     const StyledTableCell = withStyles((theme) => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: 17
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
          minWidth: 200,
        },
      });

   
    const classes = useStyles();

    return ( 
        <Fragment>
            <div className="expirationDate">
                <label className='selFontTotals' htmlFor="years">Año de vencimiento:</label>
                <Select 
                    className='selFontTotals'
                    value={{value: yearSelected, label: yearSelected}} 
                    options={yearOptions()} 
                    onChange={(option) => setYearSelected(option.value)} 
                />
            </div>
            <TableContainer component={Paper} id='cannonScrollX' className="cannonScroll">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Cantidad</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Cerrado</StyledTableCell>
                            <StyledTableCell>Vencimiento</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="cannonTableBody">
                        {renderCharters()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
     );
}
 
export default CharterTable;