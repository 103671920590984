import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {serverUrl} from '../../Helper';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Button } from '@material-ui/core';
import Statistics from './Statistics';



const useStyles = makeStyles({
    tableContainer: {
        overflowX: 'hidden',
    },
    interactionBar: {
        marginBottom: '20px'
    },
    generateBtnContainer:  {
        marginTop: '20px'
    }
});



const StatisticsScreen = () => {
    const [ loading, setLoading ] = useState(false);
    const [ stats, setStats ] = useState({});
    const [ errorMsg, setErrorMsg ] = useState();
    const classes = useStyles();
    const [ belts, setBelts ] = useState([]);
    const [ timeSelected, setTimeSelected ] = useState('historical');

    useEffect(() => {
        const getBelts = async () => {
            try {
                const currentToken = localStorage.getItem("jwt-SAMS");
                const url = serverUrl + "/rest/belt";
                const beltResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
                const data = beltResponse.data;
                
                if(data.ok){
                    setBelts(data.belts);
                } else {
                    console.error("Error al traer cintos");
                } 
            } catch (error) {
                console.error("Error al traer cintos");
            }
        } 
        getBelts();
    }, []);

    useEffect(() => {
        const getStatistics = async () => {
            setLoading(true);
            const url = `${serverUrl}/rest/statistics?${timeSelected === 'historical' ? 'historical=true' : `date=${timeSelected?.toISOString()?.substring(0,10)}`}`;
            const currentToken = localStorage.getItem("jwt-SAMS");
            try {
                const res = await axios.get(url, { headers: {Authorization: currentToken}});
                if(res?.data?.statistics) {
                    setErrorMsg(undefined);
                    setStats(res?.data?.statistics);
                } else {
                    setErrorMsg('Algo salió mal al intentar cargar las estadísticas, por favor intente de nuevo mas tarde.')
                }
            } catch (error) {
                if(error?.response?.status === 404) {
                    setErrorMsg('Aun no existen estadísticas para este período.')
                } else {
                    setErrorMsg('Algo salió mal al intentar cargar las estadísticas, por favor intente de nuevo mas tarde.')
                }
            }
            setLoading(false);
        }
        getStatistics();
    }, [timeSelected]);

    const getSelectionOptions = () => {
        const options = [{ label: 'Historico', value: 'historical' }];
        const currDate = new Date();
        const n = 5;
        
        for(let i = 0; i < n; i++) {
            let date = new Date(currDate.getFullYear() - i, currDate.getMonth());
            options.push({
                label: date.getFullYear(),
                value: date
            });
        }
        return options;
    }

    const generateStatistics = async () => {
        setLoading(true);
        const url = `${serverUrl}/rest/generate-statistics?${timeSelected === 'historical' ? 'historical=true' : `date=${timeSelected?.toISOString()?.substring(0,10)}`}`;
        const currentToken = localStorage.getItem("jwt-SAMS");
        try {
            const res = await axios.get(url, { headers: {Authorization: currentToken}});
            if(res?.data?.statistics) {
                setErrorMsg(undefined);
                setStats(res?.data?.statistics);
            } else {
                setErrorMsg('Algo salió mal al intentar cargar las estadísticas, por favor intente de nuevo mas tarde.')
            }
        } catch (error) {
            if(error?.response?.status === 404) {
                setErrorMsg('Aun no existen estadísticas para este período.')
            } else {
                setErrorMsg('Algo salió mal al intentar cargar las estadísticas, por favor intente de nuevo mas tarde.')
            }
        }
        setLoading(false);
    }


    return (
        <Grid container className="cannonMainContainer">
            <Grid item xs={12} className=''>
                <h1 className='preferencesTitle'>Estadísticas</h1>
            </Grid>
            <Grid className={classes.interactionBar} container item xs={11}>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                    <Select
                        className='selFontTotals'
                        id='timeSelect'
                        placeholder='Historico'
                        options={getSelectionOptions()}
                        name='timeSelected'
                        onChange={(option) => setTimeSelected(option.value)}
                    />
                </Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={2} className={classes.generateBtnContainer}>
                    <Button className='preferencesBtns' onClick={() => generateStatistics()}>Generar Estadísticas</Button>
                </Grid>
            </Grid>
            {
                loading ? (
                    <Grid container item xs={12}>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={5}>
                            <CircularProgress size={300} /> 
                        </Grid>
                        
                    </Grid>
                ) : errorMsg ? (<Grid item xs={12}><h2>{errorMsg}</h2></Grid>) : <Statistics belts={belts} stats={stats} />
            }
        </Grid>
    );
}
 
export default StatisticsScreen;