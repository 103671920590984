import React, {useContext, useEffect, useState} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import UserContext from '../../context/users/UserContext';
import axios from 'axios';
import {serverUrl} from '../../Helper';


const Navbar = ({setMainRendered}) => {

	const {user, setUser} = useContext(UserContext);
	const [anchorElExams, setAnchorElExams] = useState(null);

	useEffect(()=>{
		const getPrivileges = async () => {
			const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/selfuser/";
			try {
				const selfUserResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            	const data = selfUserResponse.data;
				if(data.ok){
					const userAPI = data.user;
					userAPI._id = Number.parseInt(userAPI._id);
					userAPI._privileges = Number.parseInt(userAPI._privileges);
					userAPI._idPerson = Number.parseInt(userAPI._idPerson);
					setUser(userAPI); 
				}
			} catch (error) {
				console.error(error);	
			}
		}
		getPrivileges();
	}, [setUser]);

	const handleOpenExams = (event) => {
		setAnchorElExams(event.currentTarget);
	}

	const handleCloseExams = () => {
		setAnchorElExams(null);
	}

	const selectMenuExam = (value) => {
		handleCloseExams();
		setMainRendered(value);
	}

	const renderItems = () => {
		switch(user._privileges) {
			case 6:
				return (
					<ul className="navbarItems">
						<li><button className="navbarItemsBtns" name="home" onClick={e => setMainRendered(e.target.name)} href="#0">Home</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="cannonAnual" onClick={e => setMainRendered(e.target.name)}>Cannon Anual</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="schoolCharter" onClick={e => setMainRendered(e.target.name)}>School Charter</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="examenes" onClick={handleOpenExams}>Exámenes</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="buscarAlumno" onClick={e => setMainRendered(e.target.name)}>Buscar Alumno</button></li> 
						<li><button className="navbarItemsBtns" href="#0" name="preferencias" onClick={e => setMainRendered(e.target.name)}>Preferencias</button></li>  
						<li><button className="navbarItemsBtns" href="#0" name="estadisticas" onClick={e => setMainRendered(e.target.name)}>Estadísticas</button></li>
					</ul>
				)
			case 5: 
				return (
					<ul className="navbarItems">
						<li><button className="navbarItemsBtns" name="home" onClick={e => setMainRendered(e.target.name)} href="#0">Home</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="cannonAnual" onClick={e => setMainRendered(e.target.name)}>Cannon Anual</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="schoolCharter" onClick={e => setMainRendered(e.target.name)}>School Charter</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="examenes" onClick={e => setMainRendered(e.target.name)}>Exámenes</button></li> 
						<li><button className="navbarItemsBtns" href="#0" name="buscarAlumno" onClick={e => setMainRendered(e.target.name)}>Buscar Alumno</button></li>  
						<li><button className="navbarItemsBtns" href="#0" name="preferencias" onClick={e => setMainRendered(e.target.name)}>Preferencias</button></li>
						<li><button className="navbarItemsBtns" href="#0" name="estadisticas" onClick={e => setMainRendered(e.target.name)}>Estadísticas</button></li>    
					</ul>
				)
			case 4:
				return (
						<ul className="navbarItems">
							<li><button className="navbarItemsBtns" name="home" onClick={e => setMainRendered(e.target.name)} href="#0">Home</button></li>
							<li><button className="navbarItemsBtns" href="#0" name="examenes" onClick={e => setMainRendered(e.target.name)}>Exámenes</button></li>
							<li><button className="navbarItemsBtns" href="#0" name="buscarAlumno" onClick={e => setMainRendered(e.target.name)}>Buscar Alumno</button></li>
							<li><button className="navbarItemsBtns" href="#0" name="estadisticas" onClick={e => setMainRendered(e.target.name)}>Estadísticas</button></li> 
						</ul>
				)
			default: 
					return (
						<ul className="navbarItems">
							<li><button className="navbarItemsBtns" name="home" onClick={e => setMainRendered(e.target.name)} href="#0">Home</button></li>
						</ul>
					)
		}
	}

	

    return ( 
        <nav id="mainNavbar">
			{renderItems()}
			<Menu
				style={{marginTop: '50px'}}
				anchorEl={anchorElExams}
				open={Boolean(anchorElExams)}
				onClose={handleCloseExams}
			>
				<MenuItem style={{fontSize: '1.5rem'}} className="navbarItemsBtns" href="#0" name="examenes" onClick={() => selectMenuExam("examenes")}>Exámenes C. Colores</MenuItem>
				<MenuItem style={{fontSize: '1.5rem'}} className="navbarItemsBtns" href="#0" name="examenes" onClick={() => selectMenuExam("examenesNegros")}>Exámenes C. Negros</MenuItem>
				<MenuItem style={{fontSize: '1.5rem'}} className="navbarItemsBtns" href="#0" name="examenes" onClick={() => selectMenuExam("examenesInstruccion")}>Exámenes de Instrucción</MenuItem> 
				
			</Menu>
		</nav>
     );
}
 
export default Navbar;