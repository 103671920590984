import {SET_USER} from '../../types';

//state es el incial state y action el la información del dispatch
const UserReducer = (state, action) => { 

    switch(action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }
        default: 
            return state;
    }
}

export default UserReducer; 