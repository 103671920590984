import {SET_ACADEMIES, SET_ACADEMY_SELECTED} from '../../types';

//state es el incial state y action el la información del dispatch
const AcademyReducer = (state, action) => {
    switch(action.type) {
        case SET_ACADEMIES:
            //Siempre se retorna un object con el object anterior mas el cambio.
            return {
                ...state,
                academies: action.payload,
                academiesLoading: false
            }
        case SET_ACADEMY_SELECTED:
            return {
                ...state,
                academySelected: action.payload 
            }
        default: 
            return state;
    }
}

export default AcademyReducer;