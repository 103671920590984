import React, {useReducer, useCallback} from 'react';
import CannonContext from './CannonContext';
import CannonReducer from './CannonReducer';
import {SET_CANNONS, SET_SELECTED_CANNON, ADD_CANNON, DELETE_CANNON, UPDATE_CANNON} from '../../types';



const CannonState = (props) => {

    const initialState = {
        cannons: [],
        cannonSelected: {
            _id: 0,
            _amount: "",
            _date: new Date(),
            _exp: new Date(),
            _closed: false
        }
    }

    //en el useReducer se ponen como parametros el reducer y el state inicial. 
    // state y dispatch se ocupan siempre por convención pero es lo mismo que cualquier state
    const [state, dispatch] = useReducer(CannonReducer, initialState);

    const resetCannonSelected = useCallback(() => {
        dispatch({
            type: SET_SELECTED_CANNON,
            payload: {
                _id: 0,
                _amount: "",
                _date: new Date(),
                _exp: new Date(),
                _closed: false
            }
        });
    },[]);

    const setCannons = useCallback((cannons) => {
        dispatch({
            type: SET_CANNONS,
            payload: cannons
        });
    },[]);

    const setCannonSelected = (cannon) => {
       dispatch({
        type: SET_SELECTED_CANNON,
        payload: cannon
       });
    }
    
    const addCannon = (cannon) => {
        dispatch({
         type: ADD_CANNON,
         payload: cannon
        });
     }
     
     const deleteCannon = (cannon) => {
        dispatch({
         type: DELETE_CANNON,
         payload: cannon
        });
     }

    const updateCannon = (cannon) => {
        dispatch({
         type: UPDATE_CANNON,
         payload: cannon
        });
     } 

    return (
        <CannonContext.Provider
            value={{
                cannons: state.cannons,
                cannonSelected: state.cannonSelected,
                setCannons,
                setCannonSelected,
                addCannon,
                deleteCannon,
                updateCannon,
                resetCannonSelected
            }}
        >
            {props.children}
        </CannonContext.Provider>
    )
}
 
export default CannonState; 