import React, { useEffect, useContext, useState } from 'react';
import ExamBBListTable from './ExamBBListTable';
import ExamBBTable from './ExamBBTable';
import ExamsCrudBB from './ExamsCrudBB';
import { serverUrl } from '../../Helper';
import axios from 'axios';
import ExamContext from '../../context/exam/ExamContext';
import BeltsContext from '../../context/belts/BeltsContext';

import Grid from '@material-ui/core/Grid';
import { makeStyles, Button } from '@material-ui/core';
import StudentsTable from '../StudentsTable';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }
  }));


const ExamsBB = () => {
    const classes = useStyles();

    const [sAndITables, setSAndITables] = useState(false);
    const [loadStudentsBtn, setLoadStudentsABtn] = useState(false);

    const { setLoading, setExams } = useContext(ExamContext);
    const { setBelts } = useContext(BeltsContext);

    useEffect(() => {

        const getExams = async () => {
            setLoading(true);
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/examBB/";
            try {
                const examResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
                const data = examResponse.data;

                //map to change the format of the dates
                data.exams.map((exam)=>{

                    exam._date = Date.parse(exam._date);
                    exam._date = new Date(exam._date);

                    return null;
                })
                
                if(data.ok){
                    setExams(data.exams);
                } else {
                    console.log("Error al traer examenes");
                }
            } catch (error) {
                console.log("Error al traer examenes");
            }
            setLoading(false); 
        }

        getExams();
   
    }, [setExams, setLoading]);

    useEffect(() => {

        const getBelts = async () => {
    
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/belt";
            const beltResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = beltResponse.data;
            
            if(data.ok){
                setBelts(data.belts);
            } else {
                console.log("Error al traer cintos");
            } 
        } 
        getBelts();
    }, [setBelts]);

    useEffect(() => {

        //Mejorar el nombre a la función de abajo
        const getInstructorsAcademies = async () => {
    
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/belt";
            const beltResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = beltResponse.data;
            
            if(data.ok){
                setBelts(data.belts);
            } else {
                console.log("Error al traer cintos");
            } 
        } 
        getInstructorsAcademies();
    }, [setBelts]);

    const loadStudents = () => {
        setSAndITables(true);
        setLoadStudentsABtn(true);
    }
    

    return ( 
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className="examTitle">
                    <h1 className="examTitleH1"> Exámenes de Cintos Negros</h1>
                </Grid>
                <Grid item xs={12} sm={12} className="examTable">
                    <ExamBBListTable />
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={4} className="examCrud">
                    <ExamsCrudBB />
                </Grid>
                <Grid item xs={12} sm={12} className="examTable">
                    <ExamBBTable />
                </Grid>
                <Grid className="loadStudentsBtn">
                    {
                        loadStudentsBtn ? (
                            null
                        )  : (
                            <Button href='#studentsTable' id="loadStudentsBtnWords" onClick={() => loadStudents()}>Cargar Alumnos</Button>
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} id='studentsTable' className="examAcademyTable">
                    {
                        sAndITables ? (
                            <StudentsTable />
                        )  : (
                            null
                        )
                    }
                </Grid>
            </Grid> 
        </div>
     );
}
 
export default ExamsBB;