import React, {useContext} from 'react';
import CannonContext from '../../context/cannon/CannonContext';
import AcademyContext from '../../context/academies/AcademyContext';
import {useHistory} from 'react-router-dom';
import {serverUrl} from '../../Helper';
import axios from 'axios';
import AlertContext from '../../context/alerts/AlertContext';
import Swal from 'sweetalert2';

const CannonCrud = () => {

    const {setAlert} = useContext(AlertContext);

    const cannonContext = useContext(CannonContext);
    const {cannonSelected, setCannonSelected, deleteCannon, addCannon, updateCannon} = cannonContext;
    const academyContext = useContext(AcademyContext);
    const {academySelected} = academyContext;
    const history = useHistory();

    const handleChange = (e)=>{

        if(e.target.name === "_date" || e.target.name === "_exp") {
            let date = Date.parse(e.target.value);
            date = new Date(date);

            if(isNaN(date.valueOf()) || date.getFullYear() > 3100) {
                return;
            }

            setCannonSelected({
                ...cannonSelected,
                [e.target.name]: date
            })

        }else if (e.target.name === "_closed"){
            setCannonSelected({
                ...cannonSelected,
                [e.target.name]: e.target.checked
            })
        } else {
            setCannonSelected({
                ...cannonSelected,
                [e.target.name]: e.target.value
            })
        }
    }

    const newCannon = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere agregar un Cannon?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(cannonSelected._amount === '') {
            return setAlert({
                show: true,
                msg: 'Todos los campos son obligatorios!',
                type: 'error'
            });
        }

        if(!academySelected._id) {
            return setAlert({
                show: true,
                msg: 'Seleccione una Academia!',
                type: 'error'
            });
        }

        //Add 1 day to the date
        const UTCDate = new Date(cannonSelected._date.toISOString().substring(0,10)) ;
        
        UTCDate.setDate(UTCDate.getDate() + 1);

        //Add 1 day to the experiration date
        const UTCDateExp = new Date(cannonSelected._exp.toISOString().substring(0,10)) ;
        
        UTCDateExp.setDate(UTCDateExp.getDate() + 1); 

        let cannonData = {
            idAcademy: academySelected._id,
            amount: Number.parseFloat(cannonSelected._amount),
            date: UTCDate,
            closed: cannonSelected._closed,
            exp: UTCDateExp
        }

        try {
            const url = serverUrl + "/rest/cannon";
            const serverResponse =  await axios.post(url, cannonData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                const newCannon = data.cannon;
                
                newCannon._date = Date.parse(newCannon._date);
                newCannon._date = new Date(newCannon._date);
                newCannon._exp = Date.parse(newCannon._exp);
                newCannon._exp = new Date(newCannon._exp);
                addCannon(newCannon);
                setAlert({
                    show: true,
                    msg: 'Se agregó correctamente!',
                    type: 'success'
                });
            } else {
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
                console.log("Error al traer academias");
            }  
            //console.log(serverResponse);
        } catch (error) {
            console.log("Error en el post: ", error.response);
            //error.response.status  de aca mandarlo al login si se vence el token por ejemplo (usar if con 
            //numero de error)
        }  
    }

    const delCannon = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere eliminar este Cannon?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(cannonSelected._id === 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Cannon para eliminarlo!',
                type: 'error'
            });
        }

        try {
            const url = serverUrl + "/rest/cannon/" + cannonSelected._id;
            const serverResponse =  await axios.delete(url, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                deleteCannon(cannonSelected);
                setAlert({
                    show: true,
                    msg: 'Se borro correctamente!',
                    type: 'success'
                });
            } else {
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
                console.log("Error al traer academias");
            }  
            console.log(serverResponse);
            
        } catch (error) {
            console.log("Error en el post: ", error);
            //error.response.status  de aca mandarlo al login si se vence el token por ejemplo (usar if con 
            //numero de error)
        }  
        
    }

    //updates cannons
    const cannonUpdate = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere actualizar este Cannon?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;
        
        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(cannonSelected._amount === '') {
            return setAlert({
                show: true,
                msg: 'Todos los campos son obligatorios!',
                type: 'error'
            });
        }

        if(cannonSelected._id === 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Cannon para actualizarlo!',
                type: 'error'
            });
        }


        //Add 1 day to the date
        const UTCDate = new Date(cannonSelected._date.toISOString().substring(0,10)) ;
        
        UTCDate.setDate(UTCDate.getDate() + 1);

        //Add 1 day to the experiration date
        const UTCDateExp = new Date(cannonSelected._exp.toISOString().substring(0,10)) ;
        
        UTCDateExp.setDate(UTCDateExp.getDate() + 1); 
        

        let cannonData = {
            id: cannonSelected._id,
            idAcademy: academySelected._id,
            amount: cannonSelected._amount,
            date: UTCDate,
            closed: cannonSelected._closed,
            exp: UTCDateExp
        }


        try {
            const url = serverUrl + "/rest/cannon";
            const serverResponse =  await axios.put(url, cannonData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            const cannonUpdated = data.cannon; 

            cannonUpdated._date = Date.parse(cannonUpdated._date);
            cannonUpdated._date = new Date(cannonUpdated._date);
            cannonUpdated._exp = Date.parse(cannonUpdated._exp);
            cannonUpdated._exp = new Date(cannonUpdated._exp);

            if(data.ok){
                updateCannon(cannonUpdated);
                setAlert({
                    show: true,
                    msg: 'Se actualizó correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error al traer academias");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
            console.log(serverResponse);
        } catch (error) {
            console.log("Error en el post: ", error);
            //error.response.status  de aca mandarlo al login si se vence el token por ejemplo (usar if con 
            //numero de error)
        }  
    }

    return (
        <form id='cannonform'>
            <div className="mb-3 cannonformItem">
                <label htmlFor="exampleInputEmail1" className="form-label inputFonts">Cantidad</label>
                <input value={cannonSelected._amount} name="_amount" onChange={e => handleChange(e)} type="number" className="form-control inputFonts" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="mb-3 cannonformItem">
                <label htmlFor="exampleInputPassword1" className="form-label">Fecha</label>
                <input value={cannonSelected._date.toISOString().substring(0,10)} name="_date" onChange={handleChange} type="date" className="form-control inputFonts" />
            </div>
            <div className="mb-3 cannonformItem">
                <label htmlFor="exampleInputPassword1" className="form-label">Vencimiento</label>
                <input value={cannonSelected._exp.toISOString().substring(0,10)} name="_exp" onChange={handleChange} type="date" className="form-control inputFonts"/>
            </div>
            <div className="mb-3 form-check cannonformItem">
                <label className="form-check-label" htmlFor="exampleCheck1">Cerrado</label>
                <input checked={cannonSelected._closed} name="_closed" onChange={handleChange} type="checkbox" className="form-check-input inputFonts" id="exampleCheck1" />  
            </div>
            <div className="crudBtnsPosition cannonformItem">
                <button type="button" className="btn btn-primary btnCrear btnFont" onClick={(e)=>newCannon(e)}>Crear Cannon</button>
                <button type="button" className="btn btn-primary btnEliminar btnFont" onClick={(e)=>delCannon(e)}>Eliminar</button>
                <button type="button" className="btn btn-primary btnActualizar btnFont" onClick={(e)=>cannonUpdate(e)}>Actualizar</button>
            </div>
        </form>
    );
}
 
export default CannonCrud;