import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 17
    },
    body: {
      fontSize: 14,
      minWidth: 160
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);




const ExamStatistics = ({ belts, academies, totalExams }) => {

    const getAcademiesBelts = () => {
        if(belts.length === 0) return null;
        return academies.map(eachAcademy => (
            <StyledTableRow align="left" key={eachAcademy.academy}>
                <StyledTableCell>{eachAcademy.academyName}</StyledTableCell>
                {eachAcademy.examsTotals?.map((eachBeltTotal, index) => <StyledTableCell key={index}>{eachBeltTotal}</StyledTableCell>)}
            </StyledTableRow>
        ));
    }


    const getAcademiesTotalsBelts = () => {
        if(belts.length === 0) return null;
        
        return Object.keys(totalExams).map((eachKey) => (
            <StyledTableRow align="left" key={eachKey}>
                <StyledTableCell>{belts[eachKey]._name}</StyledTableCell>
                <StyledTableCell>{totalExams[eachKey]}</StyledTableCell>
            </StyledTableRow>
        ));
    }

    const getAcademiesAddData = () => {
        return academies.map(eachAcademy => (
            <StyledTableRow align="left" key={eachAcademy.academy}>
                <StyledTableCell>{eachAcademy.academyName}</StyledTableCell>
                <StyledTableCell>{eachAcademy.statistics.colors}</StyledTableCell>
                <StyledTableCell>{eachAcademy.statistics.blacks}</StyledTableCell>
                <StyledTableCell>{eachAcademy.statistics.instructors}</StyledTableCell>
                <StyledTableCell>{eachAcademy.statistics.ir1}</StyledTableCell>
                <StyledTableCell>{eachAcademy.statistics.ir2}</StyledTableCell>
                <StyledTableCell>{eachAcademy.statistics.ir3}</StyledTableCell>
                <StyledTableCell>{eachAcademy.statistics.ir4}</StyledTableCell>
            </StyledTableRow>
        ));
    }

    const getExamsReports = () => {
        return academies.map(eachAcademy => (
            <StyledTableRow align="left" key={eachAcademy.academy}>
                <StyledTableCell>{eachAcademy.academyName}</StyledTableCell>
                <StyledTableCell>{eachAcademy.colorExams}</StyledTableCell>
                <StyledTableCell>{eachAcademy.blackExams}</StyledTableCell>
                <StyledTableCell>{eachAcademy.instructorExams?.total}</StyledTableCell>
                <StyledTableCell>{eachAcademy.instructorExams?.ir1}</StyledTableCell>
                <StyledTableCell>{eachAcademy.instructorExams?.ir2}</StyledTableCell>
                <StyledTableCell>{eachAcademy.instructorExams?.ir3}</StyledTableCell>
                <StyledTableCell>{eachAcademy.instructorExams?.ir4}</StyledTableCell>
            </StyledTableRow>
        ));
    }

    
    return ( 
        <Grid container item xs={12}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <h1>Reportes Rangos Totales</h1>
                <TableContainer className="statisticsTable" component={Paper}>
                    <Table  aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Rango</StyledTableCell>
                                <StyledTableCell id="academyName">Cantidad</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getAcademiesTotalsBelts()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <h1>Reportes Rangos por Academia</h1>
                <TableContainer className="statisticsTable" component={Paper}>
                    <Table  aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Academia</StyledTableCell>
                                {belts.map(eachBelt => {
                                    return (
                                        <StyledTableCell key={eachBelt._id} id="academyName">{eachBelt._name}</StyledTableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getAcademiesBelts()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <h1>Datos adicionales por Academia</h1>
                <TableContainer className="statisticsTable" component={Paper}>
                    <Table  aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Academia</StyledTableCell>
                                <StyledTableCell>Cintos de Colores</StyledTableCell>
                                <StyledTableCell>Cintos Negros</StyledTableCell>
                                <StyledTableCell>Instructores</StyledTableCell>
                                <StyledTableCell>Collares Rojos</StyledTableCell>
                                <StyledTableCell>Collares Rojo-Negro</StyledTableCell>
                                <StyledTableCell>Collares Negro-Rojo-Negro</StyledTableCell>
                                <StyledTableCell>Collares Negros</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getAcademiesAddData()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <h1>Reportes de examen por academia</h1>
                <TableContainer className="statisticsTable" component={Paper}>
                    <Table  aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Academia</StyledTableCell>
                                <StyledTableCell>Cintos de Colores</StyledTableCell>
                                <StyledTableCell>Cintos Negros</StyledTableCell>
                                <StyledTableCell>Instructores</StyledTableCell>
                                <StyledTableCell>Collares Rojos</StyledTableCell>
                                <StyledTableCell>Collares Rojo-Negro</StyledTableCell>
                                <StyledTableCell>Collares Negro-Rojo-Negro</StyledTableCell>
                                <StyledTableCell>Collares Negros</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getExamsReports()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
 
export default ExamStatistics;