import {
    SET_EXAMS_LOADING,
    SET_EXAMS, 
    ADD_EXAM, 
    ADD_EXAM_DETAIL, 
    UPDATE_EXAM, 
    UPDATE_EXAM_DETAIL,
    DELETE_EXAM,
    DEL_EXAM_DETAIL, 
    SET_EXAM_SELECTED,
    SET_SELECTED_DETAIL, 
    SET_FILES_GENERATED,

    SET_INSTRUCTION_EXAMS,
    ADD_INSTRUCTION_EXAM,
    ADD_INSTRUCTION_EXAM_DETAIL,
    UPDATE_INSTRUCTION_EXAM,
    UPDATE_INSTRUCTION_EXAM_DETAIL,
    DELETE_INSTRUCTION_EXAM,
    DEL_INSTRUCTION_EXAM_DETAIL,
    SET_INSTRUCTION_EXAM_SELECTED,
    SET_SELECTED_INSTRUCTION_DETAIL,
} from '../../types';


//state es el incial state y action el la información del dispatch
const ExamReducer = (state, action) => {

    const compareExams = (exam1, exam2) => {
        return exam1._date - exam2._date;
    } 

    switch(action.type) {
        case SET_EXAMS_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_EXAMS:
            return {
                ...state,
                exams: action.payload.sort(compareExams) 
            }
        case ADD_EXAM:
            const examsPlus1 = [...state.exams, action.payload];
            examsPlus1.sort(compareExams);
            return {
                ...state,
                exams:examsPlus1
            } 
            
        case ADD_EXAM_DETAIL:
            let examToAdd = {};
            const examsUpdated_a = state.exams.map(eachExam => {
                if(eachExam._id === action.payload._idExam) {
                    examToAdd = {...eachExam, _details: [...eachExam._details, action.payload]};
                    return examToAdd;
                }
                return eachExam;
            });
            examsUpdated_a.sort(compareExams);
            examToAdd = { examSelected: examToAdd };
            return {
                ...state,
                exams: examsUpdated_a,
                filesGenerated: false,
                ...examToAdd
            }
        case UPDATE_EXAM:
            const examsWith1Update = state.exams.map((eachExam)=>{
                if(eachExam._id === action.payload._id){
                    eachExam = action.payload;
                }
                return eachExam;
            });
            examsWith1Update.sort(compareExams);
            return {
                ...state,
                exams: examsWith1Update,
                filesGenerated: false
            }
        case UPDATE_EXAM_DETAIL:
            let examToUpdate = {};
            const examsUpdated_u = state.exams.map(eachExam => {
                if(eachExam._id === action.payload._idExam) {
                    examToUpdate = {...eachExam, _details: eachExam._details.map((eachDetail)=> {
                        if(eachDetail._id === action.payload._id) {
                            return action.payload;
                        }
                        return eachDetail;
                    })};
                    return examToUpdate
                }
                return eachExam;
            });
            examToUpdate = { examSelected: examToUpdate };
            examsUpdated_u.sort(compareExams);
            return {
                ...state,
                exams: examsUpdated_u,
                filesGenerated: false,
                ...examToUpdate
            }
        case DELETE_EXAM:
            const examsFiltered = state.exams.filter(eachExam => eachExam._id !== action.payload._id);
            examsFiltered.sort(compareExams); 
            return {
                ...state,
                exams: examsFiltered
            }
        case DEL_EXAM_DETAIL:
            let examToDelete = {};
            const examsUpdated_d = state.exams.map(eachExam => {
                if(eachExam._id === action.payload._idExam) {
                    examToDelete = {...eachExam, _details: eachExam._details.filter((eachDetail)=> eachDetail._id !== action.payload._id)};
                    return examToDelete;
                }
                return eachExam;
            });
            examsUpdated_d.sort(compareExams);
            examToDelete = { examSelected: examToDelete }
            return {
                ...state,
                exams: examsUpdated_d,
                detailSelected: {
                    _id: 0,
                    _idExam: 0,
                    _idStudent: 0,
                    _name: '',
                    _currentRank: 1,
                    _nm: false,
                    _renew: false,
                    _nationalAtaNumber: 0
                },
                filesGenerated: false,
                ...examToDelete
            }
        case SET_EXAM_SELECTED:
            return {
                ...state,
                examSelected: action.payload,
                filesGenerated: false
            }
        case SET_SELECTED_DETAIL:
            return {
                ...state,
                detailSelected: action.payload
            }
        case SET_FILES_GENERATED:
            return {
                ...state,
                filesGenerated: action.payload
            }





        case SET_INSTRUCTION_EXAMS:
            return {
                ...state,
                instructionExams: action.payload.sort(compareExams) 
            }
        case ADD_INSTRUCTION_EXAM:
            const instructionExamsPlus1 = [...state.instructionExams, action.payload];
            instructionExamsPlus1.sort(compareExams);
            return {
                ...state,
                instructionExams: instructionExamsPlus1
            } 
            
        case ADD_INSTRUCTION_EXAM_DETAIL:
            const instructionExamsUpdated_a = state.instructionExams.map(eachExam => {
                if(eachExam._id === action.payload._idInstructionExam) {
                    return {...eachExam, _details: [...eachExam._details, action.payload]};
                }
                return eachExam;
            });
            instructionExamsUpdated_a.sort(compareExams);
            return {
                ...state,
                instructionExams: instructionExamsUpdated_a
            }
        case UPDATE_INSTRUCTION_EXAM:
            const instructionExamsWith1Update = state.instructionExams.map((eachExam)=>{
                if(eachExam._id === action.payload._id){
                    eachExam = action.payload;
                }
                return eachExam;
            });
            instructionExamsWith1Update.sort(compareExams);
            return {
                ...state,
                instructionExams: instructionExamsWith1Update
            }
        case UPDATE_INSTRUCTION_EXAM_DETAIL:
            const instructionExamsUpdated_u = state.instructionExams.map(eachExam => {
                if(eachExam._id === action.payload._idInstructionExam) {
                    return {...eachExam, _details: eachExam._details.map((eachDetail)=> {
                        if(eachDetail._id === action.payload._id) {
                            return action.payload;
                        }
                        return eachDetail;
                    })};
                }
                return eachExam;
            });
            instructionExamsUpdated_u.sort(compareExams);
            return {
                ...state,
                instructorExams: instructionExamsUpdated_u
            }
        case DELETE_INSTRUCTION_EXAM:
            const instructionExamsFiltered = state.instructionExams.filter(eachExam => eachExam._id !== action.payload._id);
            instructionExamsFiltered.sort(compareExams); 
            return {
                ...state,
                instructionExams: instructionExamsFiltered
            }
        case DEL_INSTRUCTION_EXAM_DETAIL:
            const instructionExamsUpdated_d = state.instructionExams.map(eachExam => {
                if(eachExam._id === action.payload._idInstructionExam) {
                    return {...eachExam, _details: eachExam._details.filter((eachDetail)=> eachDetail._id !== action.payload._id)};
                }
                return eachExam;
            });
            instructionExamsUpdated_d.sort(compareExams);
            return {
                ...state,
                instructionExams: instructionExamsUpdated_d,
                instructionDetailSelected: {
                    _id: 0,
                    _idAcademy: 0,
                    _idInstructionExam: 0,
                    _idInstructor: 0,
                    _name: '',
                    _currentRank: 1,
                }
            }
        case SET_INSTRUCTION_EXAM_SELECTED:
            return {
                ...state,
                instructionExamSelected: action.payload
            }
        case SET_SELECTED_INSTRUCTION_DETAIL:
            return {
                ...state,
                instructionDetailSelected: action.payload
            }
        default: 
            return state;
    }
}

export default ExamReducer; 