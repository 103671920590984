import React, {useContext} from 'react';
import ExamContext from '../../context/exam/ExamContext';
import {useHistory} from 'react-router-dom';
import {serverUrl} from '../../Helper';
import axios from 'axios';
import AlertContext from '../../context/alerts/AlertContext';

import Swal from 'sweetalert2';

const ExamsCrudBB = () => {

    const {setAlert} = useContext(AlertContext);

    const examContext = useContext(ExamContext);
    const {examSelected, setExamSelected, addExam, updateExam, deleteExam} = examContext;

    const history = useHistory();

    const handleChange = (e)=>{
        if(e.target.name === "_date") {
            let date = Date.parse(e.target.value);
            if(isNaN(date)) return;
            date = new Date(date);

            setExamSelected({
                ...examSelected,
                [e.target.name]: date
            })
        } else {
            setExamSelected({
                ...examSelected,
                [e.target.name]: e.target.value
            })
        }
    }

    const newExam = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere agregar un Examen?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;
           
        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }


        //Add 1 day to the date
        const UTCDate = new Date(examSelected._date.toISOString().substring(0,10)) ;
        
        UTCDate.setDate(UTCDate.getDate() + 1);
        
        let examData = {
            idAcademy: 0,
            idInstructor: 0,
            instructorName: examSelected._instructorName,
            date: UTCDate,
            authorized: true,
            status: 'Borrador',
            blackBelts: true,
            details: []
        }


        try {
            const url = serverUrl + "/rest/exam";
            const serverResponse =  await axios.post(url, examData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            

            if(data.ok){

                const newExam = data.exam;
                
                newExam._date = Date.parse(newExam._date);
                newExam._date = new Date(newExam._date);

                addExam(newExam);

                setAlert({
                    show: true,
                    msg: 'Se agregó el examen correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error al crear nuevo examen");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
        } catch (error) {
            console.log("Error en el post: ", error.response);
        }  
    }

    const delExam = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere eliminar este Examen?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(examSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un examen para eliminarlo!',
                type: 'error'
            });
        }

        try {
            const url = serverUrl + "/rest/exam/" + examSelected._id;
            const serverResponse =  await axios.delete(url, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                deleteExam(examSelected);
                setAlert({
                    show: true,
                    msg: 'Se borró el examen correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error al eliminar Examen");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
            
        } catch (error) {
            console.log("Error en el post: ", error);
        }  
        
    }

    //updates exams
    const examUpdate = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere actualizar este Examen?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;
        
        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(examSelected._instructorName === '') {
            return setAlert({
                show: true,
                msg: 'Todos los campos son obligatorios!',
                type: 'error'
            });
        }

        if(examSelected._id === 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Examen para actualizarlo!',
                type: 'error'
            });
        }


        //Add 1 day to the date
        const UTCDate = new Date(examSelected._date.toISOString().substring(0,10)) ;
        
        UTCDate.setDate(UTCDate.getDate() + 1);

        let detailsToSend = [];

        if(examSelected._details) {

            detailsToSend = examSelected._details.map((eachDetail) => {
                return {
                    id: eachDetail._id,
                    idExam: eachDetail._idExam,
                    idStudent: eachDetail._idStudent,
                    name: eachDetail._name,
                    currentRank: eachDetail._currentRank,
                    nm: eachDetail._nm,
                    renew: eachDetail._renew,
                    nationalAtaNumber: eachDetail._nationalAtaNumber
                }
            });
        }
        
        

        let examData = {
            id: examSelected._id,
            idAcademy: examSelected._idAcademy,
            idInstructor: examSelected._idInstructor,
            instructorName: examSelected._instructorName,
            date: UTCDate,
            authorized: true,
            status: examSelected._status,
            blackBelts: true,
            details: detailsToSend
        }


        try {
            const url = serverUrl + "/rest/exam";
            const serverResponse =  await axios.put(url, examData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            const examUpdated = data.exam; 

            examUpdated._date = Date.parse(examUpdated._date);
            examUpdated._date = new Date(examUpdated._date);

            if(data.ok){
                updateExam(examUpdated);
                setAlert({
                    show: true,
                    msg: 'Se actualizo el examen correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error al modificar examen");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
        } catch (error) {
            console.log("Error en el post: ", error);
        }  
    }

    return (
        <form id="examCrudMainContainer">
            <div id='newExamContainer'>
                <label className="crudMainTitle">Creación/Edición de Examen</label>
                <div className="mb-3 cannonformItem">
                    <label htmlFor="exampleInputEmail1" className="form-label inputFonts">Nombre del Instructor</label>
                    <input value={examSelected._instructorName} name="_instructorName" onChange={e => handleChange(e)} type="text" className="form-control inputFonts" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3 cannonformItem">
                    <label htmlFor="exampleInputPassword1" className="form-label">Fecha</label>
                    <input value={examSelected._date.toISOString().substring(0,10)} name="_date" onChange={handleChange} type="date" className="form-control inputFonts" />
                </div>
            
                <div className="crudBtnsPosition cannonformItem">
                    <button type="button" className="btn btn-primary btnCrearExamen btnFont" onClick={(e)=>newExam(e)}>Crear Examen</button>
                    <button type="button" className="btn btn-primary btnEliminar btnFont" onClick={(e)=>delExam(e)}>Eliminar</button>
                    <button type="button" className="btn btn-primary btnActualizar btnFont" onClick={(e)=>examUpdate(e)}>Actualizar</button>
                </div>
            </div>
        </form>
    );
}
 
export default ExamsCrudBB;