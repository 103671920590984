import React, {useReducer, useCallback} from 'react';
import CharterContext from './CharterContext';
import CharterReducer from './CharterReducer';
import {SET_CHARTERS, SET_SELECTED_CHARTER, ADD_CHARTER, DELETE_CHARTER, UPDATE_CHARTER} from '../../types';



const CharterState = (props) => {

    const initialState = {
        charters: [],
        charterSelected: {
            _amount: "",
            _closed: false,
            _date: new Date(),
            _exp: new Date(),
            _id: 0,
            _idAcademy: 0
        }
    }

    //en el useReducer se ponen como parametros el reducer y el state inicial. 
    // state y dispatch se ocupan siempre por convención pero es lo mismo que cualquier state
    const [state, dispatch] = useReducer(CharterReducer, initialState);

    const resetCharterSelected = () => {
        dispatch({
            type: SET_SELECTED_CHARTER,
            payload: {
                _amount: "",
                _closed: false,
                _date: new Date(),
                _exp: new Date(),
                _id: 0,
                _idAcademy: 0
            }
        });
     }

    const setCharters = useCallback((charters) => {
        dispatch({
            type: SET_CHARTERS,
            payload: charters
        });
    },[]);

    const setCharterSelected = (charter) => {
       dispatch({
        type: SET_SELECTED_CHARTER,
        payload: charter
       });
    }
    
    const addCharter = (charter) => {
        dispatch({
         type: ADD_CHARTER,
         payload: charter
        });
     }
     
     const deleteCharter = (charter) => {
        dispatch({
         type: DELETE_CHARTER,
         payload: charter
        });
     }

    const updateCharter = (charter) => {
        dispatch({
         type: UPDATE_CHARTER,
         payload: charter
        });
     } 

    return (
        <CharterContext.Provider
            value={{
                charters: state.charters,
                charterSelected: state.charterSelected,
                setCharters,
                setCharterSelected,
                addCharter,
                deleteCharter,
                updateCharter,
                resetCharterSelected
            }}
        >
            {props.children}
        </CharterContext.Provider>
    )
}
 
export default CharterState; 