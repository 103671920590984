
//Estas son las acciones disponibles que modifican al State
export const SET_ACADEMIES = 'SET_ACADEMIES';
export const SET_ACADEMY_SELECTED = 'SET_ACADEMY_SELECTED';


export const SET_CANNONS = 'SET_CANNONS';
export const SET_SELECTED_CANNON = 'SET_SELECTED_CANNON';
export const ADD_CANNON = 'ADD_CANNON';
export const DELETE_CANNON = 'DELETE_CANNON';
export const UPDATE_CANNON = 'UPDATE_CANNON';


export const SET_CHARTERS = 'SET_CHARTERS';
export const SET_SELECTED_CHARTER = 'SET_SELECTED_CHARTER';
export const ADD_CHARTER = 'ADD_CHARTER';
export const DELETE_CHARTER = 'DELETE_CHARTER';
export const UPDATE_CHARTER = 'UPDATE_CHARTER';


export const SET_EXAMS_LOADING = 'SET_EXAMS_LOADING';
export const SET_EXAMS = 'SET_EXAMS';
export const ADD_EXAM = 'ADD_EXAM';
export const ADD_EXAM_DETAIL = 'ADD_EXAM_DETAIL';
export const UPDATE_EXAM = 'UPDATE_EXAM';
export const UPDATE_EXAM_DETAIL = 'UPDATE_EXAM_DETAIL';
export const DELETE_EXAM = 'DELETE_EXAM';
export const DEL_EXAM_DETAIL = ' DEL_EXAM_DETAIL';

export const SET_EXAM_SELECTED = 'SET_EXAM_SELECTED';
export const SET_SELECTED_DETAIL = 'SET_SELECTED_DETAIL';
export const SET_FILES_GENERATED = 'SET_FILES_GENERATED';


export const SET_SC_DURATION = 'SET_SC_DURATION';
export const SET_SC_PRICE = 'SET_SC_PRICE';
export const SET_CANNON_PRICE = 'SET_CANNON_PRICE';
export const SET_ONES_PRICE = 'SET_ONES_PRICE';




export const SET_INSTRUCTION_EXAMS = 'SET_INSTRUCTION_EXAMS';
export const ADD_INSTRUCTION_EXAM = 'ADD_INSTRUCTION_EXAM';
export const ADD_INSTRUCTION_EXAM_DETAIL = 'ADD_INSTRUCTION_EXAM_DETAIL';
export const UPDATE_INSTRUCTION_EXAM = 'UPDATE_INSTRUCTION_EXAM';
export const UPDATE_INSTRUCTION_EXAM_DETAIL = 'UPDATE_INSTRUCTION_EXAM_DETAIL';
export const DELETE_INSTRUCTION_EXAM = 'DELETE_INSTRUCTION_EXAM';
export const DEL_INSTRUCTION_EXAM_DETAIL = 'DEL_INSTRUCTION_EXAM_DETAIL';
export const SET_INSTRUCTION_EXAM_SELECTED = 'SET_INSTRUCTION_EXAM_SELECTED';
export const SET_SELECTED_INSTRUCTION_DETAIL = 'SET_SELECTED_INSTRUCTION_DETAIL';


export const SET_ALERT = 'SET_ALERT';

export const SET_USER = 'SET_USER';

export const SET_BELTS = 'SET_BELTS';
export const SET_INSTRUCTOR_RANKS = 'SET_INSTRUCTOR_RANKS';

