import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { serverUrl, getBeltName } from '../Helper';
import Select from 'react-select';
import AcademyContext from '../context/academies/AcademyContext';
import ExamContext from '../context/exam/ExamContext';
import BeltContext from '../context/belts/BeltsContext';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';



const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 25
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
    tableContainer: {
        overflowX: 'hidden',
    }
});

const SearchStudent = () => {

    const { academies, academySelected, setAcademySelected } = useContext(AcademyContext);
    const { exams, setExams } = useContext(ExamContext);
    const { belts, setBelts } = useContext(BeltContext);

    const [ name, setName ] = useState('');

    const classes = useStyles();

    useEffect(() => {
        const getBelts = async () => {
            try {
                const currentToken = localStorage.getItem("jwt-SAMS");
                const url = serverUrl + "/rest/belt";
                const beltResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
                const data = beltResponse.data;
                
                if(data.ok){
                    setBelts(data.belts);
                } else {
                    console.error("Error al traer cintos");
                } 
            } catch (error) {
                console.error("Error al traer cintos");
            }
        } 
        getBelts();
    },[setBelts]);

    useEffect(() => {
        const getExams = async () => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/exam/";
            const examResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = examResponse.data;

            //map to change the format of the dates
            data.exams.map((exam)=>{
                exam._date = Date.parse(exam._date);
                exam._date = new Date(exam._date);
                return null;
            })
            if(data.ok){
                setExams(data.exams);
            } else {
                console.error("Error al traer examenes");
            }  
        }
        getExams();
    }, [setExams]);

    const getStudentExams = () => {
        let rowsToReturn = [];
        if(name === '') return null;
        exams.filter(eachExam => eachExam._status === 'Cerrado' && eachExam._idAcademy === academySelected._id).map(eachExam => {
            rowsToReturn = [...rowsToReturn, eachExam._details.filter(eachDetail => eachDetail._name.toLowerCase().includes(name.toLowerCase())).map(eachDetail => {
                return(
                    <StyledTableRow align="left" key={eachDetail._id}>
                        <StyledTableCell >{eachDetail._name}</StyledTableCell>
                        <StyledTableCell >{getBeltName(eachDetail._currentRank, belts)}</StyledTableCell>
                        <StyledTableCell >{eachExam._date.toLocaleDateString()}</StyledTableCell>
                    </StyledTableRow>
                );
            })];
            return null;
        });
        return rowsToReturn;
    }

    return ( 
        <Grid container className="cannonMainContainer">
            <Grid item xs={12} className="cannonTitle">
                <h1 className="cannonEditionTitle">Buscar Alumno</h1>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <Select 
                        className='selFontTotals studentsSelect'
                        value={academySelected ? {value: academySelected, label: academySelected._name} : null}
                        options={
                            academies.map(eachAcademy => {
                                return {value: eachAcademy, label: eachAcademy._name}
                            })
                        }
                        onChange={(option) => setAcademySelected(option.value)} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <input type="text" className='emailInput form-control inputFonts marks' placeholder="Nombre" value={name} onChange={e => setName(e.target.value)} />
                </Grid>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
                <TableContainer className={classes.tableContainer} id='academiesContainer' component={Paper}>
                    <Table className={classes.table} aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Nombre</StyledTableCell>
                                <StyledTableCell id="academyName">Rango al que pasa</StyledTableCell>
                                <StyledTableCell>Fecha</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getStudentExams()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    );
}
 
export default SearchStudent;