import {SET_CHARTERS, SET_SELECTED_CHARTER, ADD_CHARTER, DELETE_CHARTER, UPDATE_CHARTER} from '../../types';


//state es el incial state y action el la información del dispatch
const CharterReducer = (state, action) => {

    const compareCharters = (charter1, charter2) => {
        return charter1._date - charter2._date;
    } 

    switch(action.type) {
        case SET_CHARTERS:
            return {
                ...state,
                charters: action.payload.sort(compareCharters) 
            }
        case SET_SELECTED_CHARTER:
            return {
                ...state,
                charterSelected: action.payload
            }
        case ADD_CHARTER:
            const chartersPlus1 = [...state.charters, action.payload];
            chartersPlus1.sort(compareCharters);
            return {
                ...state,
                charters:chartersPlus1 
            } 
        case DELETE_CHARTER:
            const chartersFiltered = state.charters.filter(eachCharter => eachCharter._id !== action.payload._id);
            chartersFiltered.sort(compareCharters); 
            return {
                ...state,
                charters: chartersFiltered,
                charterSelected: {
                    _amount: "",
                    _closed: false,
                    _date: new Date(),
                    _exp: new Date(),
                    _id: 0,
                    _idAcademy: 0
                }
            }
        case UPDATE_CHARTER:
            const chartersWith1Update = state.charters.map((eachCharter)=>{
                if(eachCharter._id === action.payload._id){
                    eachCharter = action.payload;
                }
                return eachCharter;
            });
            chartersWith1Update.sort(compareCharters);
            return {
                ...state,
                charters:chartersWith1Update
            }

        default: 
            return state;
    }
}

export default CharterReducer; 