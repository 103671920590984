
//export const serverUrl = process.env.REACT_APP_SERVER_URL || "";

export const  serverUrl = ((process.env.NODE_ENV === 'production') ? '' : 'http://localhost:3001');

export const beltsToOmit = [36, 38];

export const getBeltName = (idCurrentRank, belts) => {
    let rankAcquire = '';
    if(idCurrentRank < belts.length){
        if(idCurrentRank === 35){
            rankAcquire = belts.find(eachBelt => eachBelt._id === idCurrentRank)._name;
        }else{
            belts.map((eachBelt) => {
                if((idCurrentRank + 1) === eachBelt._id){
                    rankAcquire = eachBelt._name;
                }
                return undefined;
            })
        }
        return rankAcquire;
    } else {
        rankAcquire = 'Error';
    };
}