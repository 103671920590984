import {SET_ALERT} from '../../types';

//state es el incial state y action el la información del dispatch
const AlertReducer = (state, action) => {
    switch(action.type) {
        case SET_ALERT:
            return {
                ...state,
                alert: action.payload 
            }
        default: 
            return state;
    }
}

export default AlertReducer;