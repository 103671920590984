import React from 'react';
import Grid from '@material-ui/core/Grid';

const GeneralStatistics = ({ examsColors, examsBlacks, nms, students, instructors, avgAge, males, females }) => {
    return ( 
        <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Examenes C. Colores</h2>
                <h2>{examsColors}</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Exámenes C. Negros</h2>
                <h2>{examsBlacks}</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Nuevos Miembros</h2>
                <h2>{nms}</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Alumnos Totales</h2>
                <h2>{students}</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Instructores Totales</h2>
                <h2>{instructors}</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Edad Promedio</h2>
                <h2>{avgAge} años</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Varones Totales</h2>
                <h2>{males}</h2>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <h2>Mujeres Totales</h2>
                <h2>{females}</h2>
            </Grid>
        </Grid>
    );
}
 
export default GeneralStatistics;