import React from 'react';
import { useHistory } from 'react-router-dom';


const AccountBar = () => {

    const history = useHistory();

    const loginOut = () => {
        localStorage.removeItem("jwt-SAMS");
        history.push("/");
    }

    return ( 
        <header className="app-header">
            <button className="welcome">
                SAMS
            </button>
            <button type="button" onClick={loginOut} className="closeSesion">
                Cerrar Sesión
            </button>
        </header>
     );
}
 
export default AccountBar;