import React, { useState, useEffect, useContext } from 'react'
import ExamInstructionListTable from './ExamInstuctionListTable';
import InstructionExamsCrud from './InstructionExamsCrud';
import InstructionExamTable from './InstructionExamTable';
import InstructorsTable from '../InstructorsTable';
import {serverUrl} from '../../Helper';
import axios from 'axios';
import ExamContext from '../../context/exam/ExamContext';
import BeltsContext from '../../context/belts/BeltsContext';


import Grid from '@material-ui/core/Grid';
import { makeStyles, Button } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }
  }));

const ExamsInstruction = () => {
    const classes = useStyles();
    const { setLoading, setInstructionExams } = useContext(ExamContext);
    const { setInstructorRanks } = useContext(BeltsContext);

    const [instructorTable, setInstructorTable] = useState(false);
    const [loadStudentsBtn, setLoadStudentsABtn] = useState(false);

    useEffect(() => {
        const getExams = async () => {
            setLoading(true);
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/instructionExam/";
            try {
                const examResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
                const data = examResponse.data;

                //map to change the format of the dates
                data.instructionExams.map((exam)=>{
                    exam._date = Date.parse(exam._date);
                    exam._date = new Date(exam._date);
                    return null;
                })
                
                if(data.ok){
                    setInstructionExams(data.instructionExams);
                } else {
                    console.log("Error al traer examenes");
                }
            } catch (error) {
                console.log("Error al traer examenes");
            }
            setLoading(false);
        }

        getExams();
    }, [setInstructionExams, setLoading]);

    useEffect(() => {
        const getInstructorRanks = async () => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/instructorRanks";
            const beltResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = beltResponse.data;
            
            if(data.ok){
                setInstructorRanks(data.instructorRanks);
            } else {
                console.log("Error al traer cintos");
            } 
        } 
        getInstructorRanks();
    }, [setInstructorRanks]);


    const loadInstructors = () => {
        setInstructorTable(true);
        setLoadStudentsABtn(true);
    }

    return ( 
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className="examTitle">
                    <h1 className="examTitleH1"> Exámenes de Instrucción</h1>
                </Grid>
                <Grid item xs={12} sm={12} className="examTable">
                    <ExamInstructionListTable />
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={4} className="examCrud">
                    <InstructionExamsCrud />
                </Grid>
                <Grid item xs={12} sm={12} className="examTable">
                    <InstructionExamTable />
                </Grid>
                <Grid className="loadStudentsBtn">
                    {
                        loadStudentsBtn ? (
                            null
                        )  : (
                            <Button href='#studentsTable' id="loadStudentsBtnWords" onClick={() => loadInstructors()}>Cargar Instructores</Button>
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} id='test' className="examAcademyTable">
                    {
                        instructorTable ? (
                            <InstructorsTable />
                        )  : (
                            null
                        )
                    }
                </Grid>
            </Grid> 
        </div>
    );
}
 
export default ExamsInstruction;