import { SET_BELTS, SET_INSTRUCTOR_RANKS } from '../../types';

const BeltsReducer = (state, action) => {
    switch(action.type) {
        case SET_BELTS:
            return {
                ...state,
                belts: action.payload 
            }
        case SET_INSTRUCTOR_RANKS:
            return {
                ...state,
                instructorRanks: action.payload 
            }
        default: 
            return state;
    }
}

export default BeltsReducer; 