import React, {useReducer, useCallback} from 'react';
import {SET_ALERT} from '../../types';
import AlertContext from './AlertContext';
import AlertReducer from './AlertReducer';



const AlertState = (props) => {

    const initialState = {
        alert: {
            show: false,
            msg: '',
            type: ''
        }
    }

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const setAlert = useCallback((alert) => {
        dispatch({
            type: SET_ALERT,
            payload: alert
        });
    },[]);



    return (
        <AlertContext.Provider
            value={{
                alert: state.alert,
                setAlert
            }}
        >
            {props.children}
        </AlertContext.Provider>
    )
}
 
export default AlertState;
