import React, {useContext, useState} from 'react';
import CharterContext from '../../context/schoolCharter/CharterContext';
import AcademyContext from '../../context/academies/AcademyContext';
import PreferencesContext from '../../context/preferences/PreferencesContext';
import Select from 'react-select';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const ChartersTotals = () => {

    const academyContext = useContext(AcademyContext);
    const {academies} = academyContext;

    const charterContext = useContext(CharterContext);
    const {charters} = charterContext;

    const {scPrice} = useContext(PreferencesContext);

    const [yearSelected, setYearSelected] = useState('Seleccione un año'); 

    const renderTotals = () => {

        let rowsToPrint = [];
        let totalAmount;

        academies.map((eachAcademy) => {
            
            totalAmount = 0;

            charters.map((eachCharter) => {
                
                if (eachCharter._idAcademy === eachAcademy._id && eachCharter._exp.getFullYear() === yearSelected) {
                    totalAmount +=  eachCharter._amount;
                }
                return undefined;
            });

            let charterTotal = scPrice - totalAmount;

            rowsToPrint.push(
                <StyledTableRow key={eachAcademy._id} className={charterTotal <= 0 ? "paid" : null }>
                    <StyledTableCell>{eachAcademy._name}</StyledTableCell>
                    <StyledTableCell>{charterTotal}</StyledTableCell>
                </StyledTableRow>
            );
            return undefined;
        });

        return rowsToPrint;
    }; 

    const yearOptions = () => {

        const yearsObj = {};

        const years = charters.map((eachCharter)=> {

            const year = eachCharter._exp.getFullYear();

            if(!yearsObj[year]){
                yearsObj[year] = year;
                return Number.parseInt(year);    
            }
            return undefined;
        }).filter(eachYear => eachYear !== undefined);
        years.sort();
        const options = years.map((eachYear)=>{
            return {
                value: eachYear, label: eachYear
            }   
        });
        return options;
    }

    //From here I used Material UI

    const StyledTableCell = withStyles((theme) => ({
        head: {
          color: theme.palette.common.black,
          fontSize: 20,
          fontWeight: 600
          
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
          minWidth: 200,
        },
      });

   
    const classes = useStyles();

    return ( 
        <div>
            <div className="charterExpirationDate">
                <h1 className="cannonsTotalsTitle">Charters (totales)</h1>
                <label className='expFontTotals' htmlFor="years">Seleccione un año:</label>
                <Select 
                    className='selFontTotals'
                    value={{value: yearSelected, label: yearSelected}} 
                    options={yearOptions()} 
                    onChange={(option) => setYearSelected(option.value)} 
                />
            </div>
            <TableContainer component={Paper} className="cannonScroll">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow id='chartersTotalsHead'>
                            <StyledTableCell>Nombre de Academia</StyledTableCell>
                            <StyledTableCell>Adeuda</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="cannonTableBody">
                        {renderTotals()}
                    </TableBody>   
                </Table>  
            </TableContainer>
        </div>
     );
}
 
export default ChartersTotals;