import React, {useEffect, useContext} from 'react';
import AcademyTable from '../AcademyTable';
import ExamListTable from './ExamListTable';
import ExamTable from './ExamTable';
import Credentials from '../Credentials';
import ExamsDetailsCrud from './ExamsDetailsCrud';
import ExamsCrud from './ExamsCrud';
import { serverUrl } from '../../Helper';
import axios from 'axios';
import ExamContext from '../../context/exam/ExamContext';
import BeltsContext from '../../context/belts/BeltsContext';
import AcademyContext from '../../context/academies/AcademyContext';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const Exams = () => {
    const classes = useStyles();

    const { setExams, setLoading } = useContext(ExamContext);
    const { setBelts } = useContext(BeltsContext);
    const { academySelected } = useContext(AcademyContext);

    useEffect(() => {
        const getExams = async () => {
            if(!academySelected?._id) return;
            setLoading(true);
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + `/rest/examByAcademy/${academySelected._id}`;
            try {
                const examResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
                const data = examResponse.data;

                //map to change the format of the dates
                data.exams.map((exam) => {
                    exam._date = Date.parse(exam._date);
                    exam._date = new Date(exam._date);
                    return null;
                });
                
                if(data.ok) {
                    setExams(data.exams);
                } else {
                    console.error("Error al traer examenes");
                }
            } catch (error) {
                console.error("Error al traer examenes");
            }
            setLoading(false);
        }
        getExams();
    }, [setExams, setLoading, academySelected]);

    useEffect(() => {
        const getBelts = async () => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/belt";
            const beltResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = beltResponse.data;
            
            if(data.ok) {
                setBelts(data.belts);
            } else {
                console.log("Error al traer cintos");
            } 
        } 
        getBelts();
    }, [setBelts]);

    
    

    return ( 
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className="examTitle">
                    <h1 className="examTitleH1"> Exámenes</h1>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={3} className="examAcademyTable">
                    <AcademyTable />
                </Grid>
                <Grid item xs={12} sm={3} className="examTotalsTable">
                    <ExamListTable />
                </Grid>
                <Grid item xs={12} className="examTable">
                    <ExamTable />
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={4} className="examCrud">
                    <ExamsCrud />
                </Grid>
                <Grid item xs={12} sm={4} className="examDetailsCrud">
                    <ExamsDetailsCrud />
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} className="filesBtns">
                    <Credentials />
                </Grid>
            </Grid> 
        </div>
     );
}
 
export default Exams;