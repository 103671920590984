import React, {useState, useRef, useEffect, useContext} from 'react';
import Select from 'react-select';
import AcademyContext from '../context/academies/AcademyContext';
import ExamContext from '../context/exam/ExamContext';
import AlertContext from '../context/alerts/AlertContext';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {serverUrl} from '../Helper';
import Swal from 'sweetalert2';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@material-ui/core';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 17
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 200,
    },
});

const StudentsTable = () => {
    const history = useHistory();
    const { academies } = useContext(AcademyContext);
    const { examSelected, addExamDetail, exams } = useContext(ExamContext);
    const {setAlert} = useContext(AlertContext);
    const [academySelected, setAcademySelected] = useState(null);
    const [students, setStudents] = useState([]);
    const [studentSelected, setStudentSelected] = useState(null);
    const [instructors, setInstructors] = useState([]);
    const [instructorsByEachAcademy, setInstructorsByEachAcademy] = useState([]);
    const calificationRef = useRef(null);
    const examTypeRef = useRef(null);

    useEffect(() => {
        if(academySelected == null) return;
        const updateStudents = async () => {
            const url = `${serverUrl}/rest/studentsByAcademy/${academySelected._id}`;
            const currentToken = localStorage.getItem("jwt-SAMS");
            const res = await axios.get(url, { headers: {Authorization: currentToken}});
            setStudents(res.data.students);
            setInstructors(res.data.instructors);
        }
        updateStudents();
    }, [academySelected]);


    const getRowClass = (id) => {
        let rowClass = "mousePointer";
        if (studentSelected?.idstudent === id) {
            return rowClass + " rowSelected";    
        } 
        return rowClass;
    }


    const addStudentToExam = async () => {
        if(studentSelected?.idstudent <= 0 || studentSelected == null) return;

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere agregar este alumno?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        });

        if(!popUpResult.value) return;

        if(examSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Examen en donde agregar el Alumno!',
                type: 'error'
            });
        }

        if(calificationRef.current?.value === '' || isNaN(calificationRef.current?.value)) {
            return setAlert({
                show: true,
                msg: 'Ingrese una calificación para agregar el Alumno!',
                type: 'error'
            });
        }

        const examT = examTypeRef.current.state?.value?.value;

        if(examT == null) {
            return setAlert({
                show: true,
                msg: 'Seleccione Tipo de Examen!',
                type: 'error'
            });
        }

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        let detailData = {
            detail:{
                id: 0,
                idAcademy: academySelected._id,
                idExam: examSelected._id,
                calification: calificationRef.current?.value,
                examType: examT,
                idStudent: studentSelected.idstudent,
                name: studentSelected.name,
                currentRank: ((examT === 'Final') ? studentSelected.idrank : (studentSelected.idrank - 1)),
                nm: (studentSelected.nationalatanumber == null),
                renew: false,
                nationalAtaNumber: studentSelected.nationalatanumber == null ? 0 : studentSelected.nationalatanumber
            }
        }
        try {
            const url = serverUrl + "/rest/examDetailBB";
            const serverResponse =  await axios.post(url, detailData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                addExamDetail(data.detail);
                setAlert({
                    show: true,
                    msg: 'Se agregó el alumno correctamente!',
                    type: 'success'
                });
            } else {
                console.error("Error al agregar alumno");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
            
        } catch (error) {
            console.error("Error en el post: ", error.response);
        }
    }

    useEffect(() => {

        let instructorsByAcademy = 0;

        const getInstructorsforAcademy = async () => {
    
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/instructorsAcademies";
            const res =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = res.data;
            
            if(data.ok){
                instructorsByAcademy = data.academies;
                setInstructorsByEachAcademy(instructorsByAcademy);
            } else {
                console.log("Error al traer instructores");
            } 
        } 
        getInstructorsforAcademy();
    }, []);
    
    const getInstructorExams = (dni)=> {
        let amountOfStudents = 0;
        if (!instructorsByEachAcademy || dni === 0) return 0;   
        const academiesIds = getAcademiesIds(dni);

        for (const eachExam of exams) {
            if(academiesIds.includes(eachExam._idAcademy) && eachExam._status === 'Cerrado'){
                amountOfStudents += eachExam._details.length;
            }
        }
        return amountOfStudents;
    }
    
    const getAcademiesIds = (dni)=> {
        let academiesIds = [];
        for (const eachAcademy of instructorsByEachAcademy) {
            for (const eachInstructor of eachAcademy.instructors) {
                if(dni === eachInstructor.dni) {
                    academiesIds.push(eachAcademy.idAcademy);
                    break;
                }                    
            }
        }
        return academiesIds;
    }  

    const classes = useStyles();
    return (
        <Grid container item xs={12} sm={12} className="BBStudentsSection">
            <Grid container item xs={12} sm={12}>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={2}>
                    <Select 
                        className='selFontTotals studentsSelect'
                        value={academySelected ? {value: academySelected, label: academySelected._name} : null}
                        options={
                            academies.map(eachAcademy => {
                                return {value: eachAcademy, label: eachAcademy._name}
                            })
                        }
                        onChange={(option) => setAcademySelected(option.value)} 
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <input type="number" className='emailInput form-control inputFonts marks' ref={calificationRef} placeholder="Calificación" />
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
                <Grid item xs={12} sm={2}>
                    <Select 
                        className='selFontTotals'
                        ref={examTypeRef}
                        options={
                            [{value: 'Mid-Term', label: 'Mid-Term'},{value: 'Final', label: 'Final'}]
                        }
                    />
                </Grid>
                <Grid className='btnCrearGrid' item xs={12} sm={2}>
                    <button type="button" id='agregarABtn' className="btn btn-primary" onClick={() => addStudentToExam()}>Agregar  Alumno</button>
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} className="BBStudentsSectionTables">
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={4}>
                    <h2>Alumnos</h2>
                    <TableContainer component={Paper} id='alumnosTable' className="cannonScroll">
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Nombre</StyledTableCell>
                                    <StyledTableCell>Edad</StyledTableCell>
                                    <StyledTableCell>Años de Antiguedad</StyledTableCell>
                                    <StyledTableCell>Collar</StyledTableCell>
                                    <StyledTableCell>Rango Actual</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="cannonTableBody">
                                {students.map(eachStudent => (
                                    <StyledTableRow 
                                        key={eachStudent.idstudent}
                                        onClick={()=> setStudentSelected(eachStudent)}
                                        className={getRowClass(eachStudent.idstudent)}
                                    >
                                        <StyledTableCell>
                                            {eachStudent.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachStudent.age}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachStudent.antiquity}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachStudent.instructorrank}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachStudent.rank}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                                )}
                            </TableBody>  
                        </Table>  
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
                <Grid item xs={12} sm={4}>
                    <h2>Instructores</h2>
                    <TableContainer component={Paper} className="cannonScroll">
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Nombre</StyledTableCell>
                                    <StyledTableCell>Edad</StyledTableCell>
                                    <StyledTableCell>Collar</StyledTableCell>
                                    <StyledTableCell>Cantidad de Exámenes</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="cannonTableBody">
                                {instructors.map(eachInstructor => (
                                    <StyledTableRow 
                                        key={eachInstructor.idinstructor}
                                    >
                                        <StyledTableCell>
                                            {eachInstructor.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachInstructor.age}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachInstructor.instructorrank}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {getInstructorExams(Number(eachInstructor.dni))}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                                )}
                            </TableBody>  
                        </Table>  
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
     );
}
 
export default StudentsTable;