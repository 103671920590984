import React, {Fragment, useState, useEffect, useContext} from 'react'
import Navbar from '../components/layout/Navbar';
import AccountBar from './layout/AccountBar';
import Home from '../components/Home';
import CannonAnual from './Cannons/CannonAnual';
import Exams from './ColorExams/Exams';
import ExamsBB from './BBExams/ExamsBB';
import Preferences from './Preferences';
import StatisticsScreen from './Statistics/StatisticScreen';
import ExamsInstruction from './InstructionExams/ExamsInstruction';
import SearchStudent from './SearchStudent';
import SchoolCharter from './SchoolCharters/SchoolCharter';
import {useHistory} from 'react-router-dom';
import {serverUrl} from '../Helper';
import axios from 'axios';
import AcademyContext from '../context/academies/AcademyContext';
import PreferencesContext from '../context/preferences/PreferencesContext';


const Main = () => {
    const {
        setSCDuration, 
        setSCPrice, 
        setCannonPrice, 
        setoneSExamPrice
    } = useContext(PreferencesContext);
    const { setAcademies } = useContext(AcademyContext);
    
    const history = useHistory();

    useEffect(() => {
        const getAcademies = async () => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            if(!currentToken) {
                history.push("/");
            }

            const url = serverUrl + "/rest/academy";
            const academiesResponse =  await axios.get(url, {headers: {'Authorization': currentToken}})
            const data = academiesResponse.data;
    
            if(data.ok){
                setAcademies(data.academies);
            } else {
                console.log("Error al traer academias");
            }      
        }
        
        getAcademies();
    }, [history, setAcademies]);

    useEffect(() => { 

        const currentYear = new Date().getFullYear();

        const scDurationAPI = async() => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentSCDuration/" + currentYear;
            const response =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if(data.ok){
                setSCDuration(data.scDuration);
            } else {
                console.log("Error al establecer duración");
            }
        }

        const scPriceAPI = async() => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentSCPrice/" + currentYear;
            const response =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if(data.ok){
                setSCPrice(data.scPrice);
            } else {
                console.log("Error al establecer Precio");
            }
        }

        const cannonPriceAPI = async() => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentCannonPrice/" + currentYear;
            const response =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if(data.ok){
                setCannonPrice(data.cannonPrice);
            } else {
                console.log("Error al establecer Precio");
            }
        }

        const oneSExamPriceAPI = async() => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentExamPrice/" + currentYear;
            const response =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if(data.ok){
                setoneSExamPrice(data.examPrice);
            } else {
                console.log("Error al establecer Precio");
            }
        }
        scDurationAPI();
        scPriceAPI();
        cannonPriceAPI();
        oneSExamPriceAPI();
    }, [setSCDuration, setSCPrice, setCannonPrice, setoneSExamPrice]);




    const [mainRendered, setMainRendered] = useState("home");

    const renderScreen = () => {
        switch(mainRendered){
            case "home":
                return <Home />
            case "cannonAnual":
                return <CannonAnual />
            case "examenes":
                return <Exams />
            case "examenesNegros":
                return <ExamsBB />
                case "examenesInstruccion":
                return <ExamsInstruction />
            case "schoolCharter":
                return <SchoolCharter />
            case "buscarAlumno":
                return <SearchStudent />
            case "preferencias":
                return <Preferences />
            case "estadisticas":
                return <StatisticsScreen />
            default:
                return null;
        }
    }
    return ( 
            <Fragment>
                <AccountBar />
                <Navbar 
                    setMainRendered= {setMainRendered}
                />
                {renderScreen()}
            </Fragment>
     );
}
 
export default Main;