import React, {useEffect, useContext} from 'react';
import AcademyTable from '../AcademyTable';
import CharterTable from './CharterTable';
import CharterContext from '../../context/schoolCharter/CharterContext';
import {serverUrl} from '../../Helper';
import axios from 'axios';
import CharterCrud from './CharterCrud';
import ChartersTotals from './ChartersTotals';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

const SchoolCharter = () => {

    const classes = useStyles();

    const charterContext = useContext(CharterContext);

    const {setCharters} = charterContext;

    useEffect(() => {

        const getSchoolCharters= async () => {
    
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/schoolCharter/";
            const charterResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = charterResponse.data;

            //map to change the format of the dates
            data.schoolCharter.map((charter)=>{

                charter._date = Date.parse(charter._date);
                charter._date = new Date(charter._date);
                charter._exp = Date.parse(charter._exp);
                charter._exp = new Date(charter._exp);

                return null;
            })
            
            if(data.ok){
                setCharters(data.schoolCharter);
            } else {
                console.log("Error al traer academias");
            }  
        }

        getSchoolCharters();
   
    }, [setCharters]);

    return ( 
        <div className={classes.root}>
            <Grid container className="charterMainContainer">
                <Grid item xs={12} sm={4} className="charterTotals">
                    <ChartersTotals />
                </Grid>
                <Grid item xs={12} className="charterTitle">
                    <h1 className="cannonH1Title">Edición School Charter</h1>
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item xs={12} sm={3} className="scAcademiesTable">
                    <AcademyTable />
                </Grid>
                <Grid item xs={12} sm={3} className="charterTable">
                    <CharterTable />
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item xs={12} className="charterCrud">
                    <CharterCrud />
                </Grid>
            </Grid>
        </div>
     );
}
 
export default SchoolCharter;