import React, { useContext, useRef } from 'react';
import Select from 'react-select';
import ExamContext from '../../context/exam/ExamContext';
import BeltsContext from '../../context/belts/BeltsContext';
import AlertContext from '../../context/alerts/AlertContext';
import AcademyContext from '../../context/academies/AcademyContext';
import { serverUrl } from '../../Helper';
import axios from 'axios';
import { useHistory } from 'react-router-dom';


//Material UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

import Swal from 'sweetalert2';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 17
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
    table: {
      minWidth: 200,
    },
  });




const InstructionExamTable = () => {
    const classes = useStyles();
    const { academies } = useContext(AcademyContext);
    const { setInstructionDetailSelected, instructionDetailSelected, updateInstructionExam, instructionExamSelected, delInstructionExamDetail } = useContext(ExamContext);
    const { instructorRanks } = useContext(BeltsContext);
    const {setAlert} = useContext(AlertContext);

    const examInstructors = useRef('');

    const history = useHistory();


    const getRowClass = (id) => {
        let rowClass = "mousePointer";
        if (instructionDetailSelected._id === id) {
            return rowClass + " rowSelected";    
        } 
        return rowClass;
    }

    const renderExam = ()=> {
        let rankAcquire = 1;

        let counter = 0;
        if(!instructionExamSelected._details || instructionExamSelected._details.length === 0) {
            return <tr><td>Examen sin Instructores</td></tr>;
        }
        
        let rowsToPrint = instructionExamSelected._details.map((eachDetail) => {

            let academy = '';
            academies.map(eachAcademy => {
                if(eachAcademy._id === eachDetail._idAcademy){
                    academy = eachAcademy._name;
                }
                return null;
            });

            
            if(eachDetail._currentRank <= instructorRanks.length){
                if(eachDetail._currentRank === instructorRanks.length){

                    rankAcquire = instructorRanks[instructorRanks.length-1]._name;
                }else{
                    instructorRanks.map((eachBelt) => {
                        if( (eachDetail._currentRank + 1) === eachBelt._id ){
                            rankAcquire = eachBelt?._name;
                        }
                        return undefined;
                    });
                }
            } else {
                rankAcquire = 'Error';
            };

            counter++;
            return (
                <StyledTableRow 
                    key={eachDetail._id} 
                    onClick={()=>setInstructionDetailSelected(eachDetail)}
                    className={getRowClass(eachDetail._id)}
                >
                    <StyledTableCell>
                        {academy}
                    </StyledTableCell>
                    <StyledTableCell>
                        {eachDetail._name}
                    </StyledTableCell>
                    <StyledTableCell>
                        {rankAcquire}
                    </StyledTableCell>
                    <StyledTableCell>
                        {eachDetail._calification}
                    </StyledTableCell>
                </StyledTableRow>
            )  
        });
        examInstructors.current = (`${counter}`);
        return rowsToPrint;
    }

    const updateExamStatus = async (newStatus) => {
        let examUpdated = instructionExamSelected;
        examUpdated._status = newStatus;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        const detailsUpdated = [];
        for(const eachDetail of examUpdated._details){
            detailsUpdated.push({
                id: eachDetail._id,
                idAcademy: eachDetail._idAcademy,
                idInstructionExam: eachDetail._idInstructionExam,
                calification: eachDetail._calification,
                idInstructor: eachDetail._idInstructor,
                name:eachDetail._name,
                currentRank: eachDetail._currentRank,
            });
        }

        let examData = {
            id: examUpdated._id,
            date: examUpdated._date,
            status: examUpdated._status,
            details: detailsUpdated
        }

        try {
            const url = serverUrl + "/rest/instructionExam";
            const serverResponse =  await axios.put(url, examData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                updateInstructionExam(examUpdated);
            } else {
                console.error("Error al actualizar el status de examen");
            }  
        } catch (error) {
            console.error("Error en el post: ", error);
        }  

    } 

    const deleteSelectedInstructor = async () => {
        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere eliminar este Instructor?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(instructionDetailSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Instructor para Eliminarlo!',
                type: 'error'
            });
        }

        try {
            const url = `${serverUrl}/rest/instructionExamDetail/${instructionDetailSelected._id}`;
            const serverResponse =  await axios.delete(url, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                delInstructionExamDetail(instructionDetailSelected);
                setAlert({
                    show: true,
                    msg: 'Se borró el instructor correctamente!',
                    type: 'success'
                });
            } else {
                console.error("Error al borrar instructor");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
            
        } catch (error) {
            console.log("Error en el post: ", error);
        }
    }

    return (
        <>
            <div className="expirationDate">
                <h2>Fecha de Examen: {!(instructionExamSelected == null) ? instructionExamSelected._date.toLocaleDateString() : null}</h2>
            </div>
            <TableContainer component={Paper} className="cannonScroll">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Academia</StyledTableCell>
                            <StyledTableCell>Nombre</StyledTableCell>
                            <StyledTableCell>Rango al que pasa</StyledTableCell>
                            <StyledTableCell>Calificación</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="cannonTableBody">
                        {renderExam()}
                    </TableBody>  
                </Table>  
            </TableContainer>
            {
                (instructionDetailSelected._id === 0 || instructionDetailSelected?._id == null) ? null : <button className="deleteDetailBtn" onClick={() => deleteSelectedInstructor()}>Eliminar Instructor</button>
            }
            <h2>Instructores: {examInstructors.current}</h2>
            <h2 className='stateSelection'>Seleccione Estado del Examen</h2>
            <Select 
                className='selFontTotals'
                value={instructionExamSelected ? {value: instructionExamSelected._status, label: instructionExamSelected._status} : null}
                options={[
                    {value: 'Borrador', label: 'Borrador'},
                    {value: 'En Proceso', label: 'En Proceso'},
                    {value: 'Cerrado', label: 'Cerrado'}
                ]}
                onChange={(option) => updateExamStatus(option.value)} 
            />
        </>
    );
}
 
export default InstructionExamTable;