import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Grid from '@material-ui/core/Grid';


const StatisticsExamCharts = ({ examsData }) => {
    console.log(examsData)
    return ( 
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <h1>Cantidad de Examenes Por Meses</h1>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
                <BarChart
                    width={900}
                    height={400}
                    data={examsData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="colors" fill="#8884d8" />
                    <Bar dataKey="blacks" fill="#82ca9d" />
                </BarChart>
            </Grid>
            
        </Grid>
    );
}
 
export default StatisticsExamCharts;