import React, {useEffect, useContext} from 'react';
import AcademyTable from '../AcademyTable';
import CannonTable from './CannonTable';
import CannonTotals from './CannonTotals';
import CannonContext from '../../context/cannon/CannonContext';
import {serverUrl} from '../../Helper';
import axios from 'axios';
import CannonCrud from './CannonCrud';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

const CannonAnual = () => {
    
    const classes = useStyles();

    const cannonContext = useContext(CannonContext);
    const {setCannons} = cannonContext;

    useEffect(() => {

        const getCannons = async () => {

            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/cannon/";
            const cannonResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = cannonResponse.data;

            //map to change the format of the dates
            data.cannons.map((cannon)=>{

                cannon._date = Date.parse(cannon._date);
                cannon._date = new Date(cannon._date);
                cannon._exp = Date.parse(cannon._exp);
                cannon._exp = new Date(cannon._exp);

                return null;
            })
            
            if(data.ok){
                setCannons(data.cannons);
            } else {
                console.log("Error al traer academias");
            }  
            
        }

        getCannons();
   
    }, [setCannons]);

    return ( 
        <div className={classes.root}>
            <Grid container className="cannonMainContainer">
                <Grid item xs={12} className="cannonTitle">
                    <h1 className="cannonEditionTitle">Pago de Cannons (totales)</h1>
                </Grid>
                <Grid item xs={12} sm={4} className="cannonTotals">
                    <CannonTotals />
                </Grid>
                <Grid item xs={12} className="cannonTitle">
                    <h1 className="cannonEditionTitle"> Edición Cannon Anual</h1>
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item xs={12} sm={3} className="canoonAcademiesTable">
                    <AcademyTable />
                </Grid>
                <Grid item xs={12} sm={3} className="cannonTable">
                    <CannonTable />
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item xs={12} className="cannonCrud">
                    <CannonCrud />
                </Grid>
            </Grid>
        </div>
     );
}
 
export default CannonAnual;