import React, {useContext} from 'react';
import ExamContext from '../../context/exam/ExamContext';
import BeltsContext from '../../context/belts/BeltsContext';
import Select from 'react-select';
import {useHistory} from 'react-router-dom';
import {serverUrl} from '../../Helper';
import axios from 'axios';
import AlertContext from '../../context/alerts/AlertContext';

import Swal from 'sweetalert2';

const ExamsDetailsCrud = () => {

    const {setAlert} = useContext(AlertContext);

    const examContext = useContext(ExamContext);
    const {examSelected, detailSelected, setDetailSelected, addExamDetail, delExamDetail, updateExamDetail} = examContext;

    const beltsContext = useContext(BeltsContext);
    const {belts} = beltsContext;

    const history = useHistory();

    const handleChange = (e)=>{

        if(e.target.type === 'checkbox'){
            setDetailSelected({
                ...detailSelected,
                [e.target.name]: e.target.checked
            });
        } else if(e.target.type === 'number') {
            setDetailSelected({
                ...detailSelected,
                [e.target.name]: Number.parseInt(e.target.value) 
            });
        } else {
            setDetailSelected({
                ...detailSelected,
                [e.target.name]: e.target.value
            });
        }
    }

    const newDetail = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere agregar un Detalle?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        });

        if(!popUpResult.value) return;
           
        if(examSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Examen en donde agregar el Alumno!',
                type: 'error'
            });
        }

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(detailSelected._name === '') {
            return setAlert({
                show: true,
                msg: 'Todos los campos son obligatorios!',
                type: 'error'
            });
        }

        let ataNumber = 0;

        if(!isNaN(detailSelected._nationalAtaNumber)) {
            ataNumber = detailSelected._nationalAtaNumber;
        }

        let detailData = {
            detail:{
                id: detailSelected._id,
                idExam: examSelected._id,
                idStudent: detailSelected._idStudent,
                name: detailSelected._name,
                currentRank: detailSelected._currentRank,
                nm: detailSelected._nm,
                renew: detailSelected._renew,
                nationalAtaNumber: ataNumber
            }
        }

        try {
            const url = serverUrl + "/rest/examDetail";
            const serverResponse =  await axios.post(url, detailData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                addExamDetail(data.detail);
                setAlert({
                    show: true,
                    msg: 'Se agregó el alumno correctamente!',
                    type: 'success'
                });
            } else {
                console.error("Error al agregar alumno");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
            
        } catch (error) {
            console.log("Error en el post: ", error.response);
            //error.response.status  de aca mandarlo al login si se vence el token por ejemplo (usar if con 
            //numero de error)
        }  
    }

    const delDetail = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere eliminar este Alumno?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(detailSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Alumno para Eliminarlo!',
                type: 'error'
            });
        }

        try {
            const url = `${serverUrl}/rest/examDetail/${detailSelected._id}`;
            const serverResponse =  await axios.delete(url, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                delExamDetail(detailSelected);
                setAlert({
                    show: true,
                    msg: 'Se borró el alumno correctamente!',
                    type: 'success'
                });
            } else {
                console.error("Error al borrar alumno");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
            
        } catch (error) {
            console.error("Error en el post: ", error);
        }  
        
    }

    //updates detail
    const updateDetail = async (e) => {

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere actualizar este Detalle?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;
        
        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(detailSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Alumno para Actualizarlo!',
                type: 'error'
            });
        }

        if(detailSelected._name === '') {
            return setAlert({
                show: true,
                msg: 'Todos los campos son obligatorios!',
                type: 'error'
            });
        }

        

        let ataNumber = 0;

        if(!isNaN(detailSelected._nationalAtaNumber)) {
            ataNumber = detailSelected._nationalAtaNumber;
        }

        let detailData = {
            detail:{
                id: detailSelected._id,
                idExam: examSelected._id,
                idStudent: detailSelected._idStudent,
                name: detailSelected._name,
                currentRank: detailSelected._currentRank,
                nm: detailSelected._nm,
                renew: detailSelected._renew,
                nationalAtaNumber: ataNumber
            }
        }

        try {
            const url = serverUrl + "/rest/examDetail";
            const serverResponse =  await axios.put(url, detailData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                updateExamDetail(detailSelected);
                setAlert({
                    show: true,
                    msg: 'Se actualizo el alumno correctamente!',
                    type: 'success'
                });
            } else {
                console.error("Error al actualizar el alumno");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
            
        } catch (error) {
            console.error("Error en el post: ", error.response);
            //error.response.status  de aca mandarlo al login si se vence el token por ejemplo (usar if con 
            //numero de error)
        }  
    }

    return (
        <form className='examDetailsMainContainer'>
            <div id='examDetailInnerContainer'>
                <label className="crudMainTitle">Creación/Edición de Alumno/s</label>
                <div className="mb-3 cannonformItem">
                    <label htmlFor="studentName" className="form-label inputFonts">Nombre</label>
                    <input value={detailSelected._name} name="_name" onChange={e => handleChange(e)} type="text" className="form-control inputFonts" id="studentName"/>
                </div>
                <div className="mb-3 cannonformItem">
                    <label htmlFor="exampleInputPassword1" className="form-label">Rango al que pasa</label>
                    <Select
                        id='beltsColors'
                        placeholder='Seleccione un rango' 
                        //value={{value: detailSelected._currentRank + 1, label: getBeltName(detailSelected._currentRank + 1)}} 
                        options={belts.filter(eachBelt => eachBelt._id < 37).map((eachBelt) => {
                            return {
                                value: eachBelt._id, 
                                label: eachBelt._name
                            }
                        })}
                        name='rankAcquire'
                        onChange={
                            (option) => setDetailSelected({
                                ...detailSelected,
                                _currentRank: option.value - 1
                            })
                        }
                    />
                </div>
                <div className="mb-3 cannonformItem">
                    <label htmlFor="ataNumber" className="form-label inputFonts">Numero ATA</label>
                    <input value={detailSelected._nationalAtaNumber} name="_nationalAtaNumber" onChange={e => handleChange(e)} type="number" className="form-control inputFonts" id="ataNumber"/>
                </div>
                <div className="mb-3 cannonformItem">
                    <label htmlFor='chbNM'>Nuevo Miembro</label>
                    <input checked={detailSelected._nm} id='chbNM' name="_nm" onChange={handleChange} type="checkbox" className='form-check-input lBox'/>
                </div>
                <div className="mb-3 cannonformItem">
                <label htmlFor='chbRN'>Renueva Carnet</label>
                    <input checked={detailSelected._renew} id='chbRN' name="_renew" onChange={handleChange} type="checkbox" className='form-check-input lBox'/>
                </div>
                <div className="crudBtnsPosition cannonformItem">
                    <button type="button" className="btn btn-primary btnCrear btnFont" onClick={(e)=>newDetail(e)}>Agregar Alumno</button>
                    <button type="button" className="btn btn-primary btnEliminar btnFont" onClick={(e)=>delDetail(e)}>Eliminar</button>
                    <button type="button" className="btn btn-primary btnActualizar btnFont" onClick={(e)=>updateDetail(e)}>Actualizar</button>
                </div>
            </div>
        </form>
    );
}
 
export default ExamsDetailsCrud;