import React, {useState, useContext} from 'react';
import {serverUrl} from '../Helper';
import axios from 'axios';
import ExamContext from '../context/exam/ExamContext';
import AcademyContext from '../context/academies/AcademyContext';
import { Button, CircularProgress } from '@material-ui/core';
import {useHistory} from 'react-router-dom';

import AlertContext from '../context/alerts/AlertContext';

import Swal from 'sweetalert2';

const Credentials = () => {

    const {setAlert} = useContext(AlertContext);


    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);

    const examContext = useContext(ExamContext);
    const {examSelected, filesGenerated, setFilesGenerated} = examContext;

    const academyContext = useContext(AcademyContext);
    const {academySelected} = academyContext;

    const history = useHistory();

    const generateCC = async () => {
        if(loading) return;

        const popUpResult = await Swal.fire({
            title: 'Corfima creación de Credenciales y/o Certificados?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;

        setLoading(true);

        if(examSelected._id === 0) {
            setLoading(false);
            return setAlert({
                show: true,
                msg: 'Seleccione un Examen!',
                type: 'error'
            });
        }

        if(examSelected._details.length === 0) {
            setLoading(false);
            return setAlert({
                show: true,
                msg: 'Error: Examen sin alumno/s!',
                type: 'error'
            });
        }

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        let examDetails = examSelected._details.map((eachExam)=>{
            return {
                id: eachExam._id,
                currentRank: eachExam._currentRank,
                idExam: eachExam._idExam,
                idStudent: eachExam._idStudent,
                name: eachExam._name,
                nationalAtaNumber: eachExam._nationalAtaNumber,
                nm: eachExam._nm,
                renew: eachExam._renew,
            };
        });

        const UTCDate = new Date(examSelected._date.toISOString().substring(0,10)) ;
            
        UTCDate.setDate(UTCDate.getDate());

        let examData = {
            location: academySelected._location,
            idAcademy: academySelected._id,
            details: examDetails,
            date: UTCDate,
            idExam: examSelected._id
        }

        try {
            const url = serverUrl + "/rest/files";
            const serverResponse =  await axios.post(url, examData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                setFilesGenerated(true);
            } else {
                setFilesGenerated(false);
                console.error("Error al generar credenciales");
            }  
            setLoading(false);
        } catch (error) {
            console.log(error);
            console.error("Error en el post: ", error.response);
            setLoading(false);
            setAlert({
                show: true,
                msg: 'Ocurrió un error!',
                type: 'error'
            });
        }  
    };

    const downloadCC = async ()=>{

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        try {
            const url = `${serverUrl}/rest/files/${examSelected._id}`;
            const serverResponse =  await axios.get(url, {headers: {'Authorization': currentToken}, responseType: 'arraybuffer'});
            const urlToDownload = window.URL.createObjectURL(new Blob([serverResponse.data], {type: 'application/zip'}));
            window.open(urlToDownload);  
            
        } catch (error) {
            console.error("Error en el post: ", error.response);
            setAlert({
                show: true,
                msg: 'Ocurrió un error!',
                type: 'error'
            });
        }  
        
    };


    const sendCC = async (e)=>{
        e.preventDefault();
        setLoadingEmail(true);
        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        try {
            const url = `${serverUrl}/rest/sendFiles?id=${examSelected._id}&email=${email}`;
            const serverResponse =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;
            
            if(data.ok){
                console.log('sent');
                setAlert({
                    show: true,
                    msg: 'El correo fue enviado correctamente!',
                    type: 'success'
                });
                setLoadingEmail(false);
            } else {
                console.error("Error al enviar mail");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error! Puede que no se haya enviado su correo',
                    type: 'error'
                });
                setLoadingEmail(false);
            }   
        } catch (error) {
            console.error("Error en el post: ", error.response);
            setAlert({
                show: true,
                msg: 'Ocurrió un error!',
                type: 'error'
            });
            setLoadingEmail(false);
        }  
    };

    return ( 
        <div className='conditionalBtns'>
            {
                !filesGenerated 
                ?<Button className={'ccBtns' + (loading ? ' generatingCertif' : '')} variant="outlined" onClick={() => generateCC()}>{loading ? '' : 'Generar Certificaciones y Credenciales'}</Button>
                :
                <div id='downloadSendBtns'>
                    <div id='downloadEmail'>
                        <Button className='ccBtns' variant="outlined"  onClick={() => downloadCC()}>Descargar Archivos</Button>
                    </div>
                    <form id='sendEmail' onSubmit={(e) => sendCC(e)}>
                        <input type='email' className='emailInput form-control inputFonts' placeholder='email...' required value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                        <Button type='submit' className='ccBtns' variant="outlined" >Enviar por mail</Button>
                        {loadingEmail ? <CircularProgress /> : null}
                    </form>
                </div>
            }
        </div>
     );
}
 
export default Credentials;







