import {SET_CANNONS, SET_SELECTED_CANNON, ADD_CANNON, DELETE_CANNON, UPDATE_CANNON} from '../../types';


//state es el incial state y action el la información del dispatch
const CannonReducer = (state, action) => {

    const compareCannons = (cannon1, cannon2) => {
        return cannon1._date - cannon2._date;
    } 

    switch(action.type) {
        case SET_CANNONS:
            return {
                ...state,
                cannons: action.payload.sort(compareCannons) 
            }
        case SET_SELECTED_CANNON:
            return {
                ...state,
                cannonSelected: action.payload
            }
        case ADD_CANNON:
            const cannonsPlus1 = [...state.cannons, action.payload];
            cannonsPlus1.sort(compareCannons);
            return {
                ...state,
                cannons:cannonsPlus1 
            } 
        case DELETE_CANNON:

            const cannonsFiltered = state.cannons.filter(eachCannon => eachCannon._id !== action.payload._id);
            cannonsFiltered.sort(compareCannons); 
            return {
                ...state,
                cannons: cannonsFiltered,
                cannonSelected: {
                    _id: 0,
                    _amount: "",
                    _date: new Date(),
                    _exp: new Date(),
                    _closed: false
                }
            }
        case UPDATE_CANNON:
            const cannonsWith1Update = state.cannons.map((eachcannon)=>{
                if(eachcannon._id === action.payload._id){
                    eachcannon = action.payload;
                }
                return eachcannon;
            });
            cannonsWith1Update.sort(compareCannons);
            return {
                ...state,
                cannons:cannonsWith1Update
            }
        default: 
            return state;
    }
}

export default CannonReducer; 