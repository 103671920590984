import React, {useContext, useRef} from 'react';
import Select from 'react-select';
import ExamContext from '../../context/exam/ExamContext';
import BeltsContext from '../../context/belts/BeltsContext';
import { serverUrl, getBeltName } from '../../Helper';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

//Material UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const ExamTable = () => {
    const { setDetailSelected, detailSelected, updateExam, examSelected } = useContext(ExamContext);
    const { belts } = useContext(BeltsContext);

    const examStudents = useRef('');
    const examNM = useRef('');
    const examRenews = useRef('');

    const history = useHistory();


    const getRowClass = (id) => {

        let rowClass = "mousePointer";

        if (detailSelected._id === id) {
            return rowClass + " rowSelected";    
        } 

        return rowClass;
    }

    const renderExam = ()=> {

        let renewCredential = '';
        let newMember = '';
        let rankAcquire = 1;

        let counter = 0;
        let NMCounter = 0;
        let RenewsCounter = 0;
        if(!examSelected._details || examSelected._details.length === 0) {
            return <tr><td>Examen sin alumnos</td></tr>;
        }
        
        let rowsToPrint = examSelected._details.map((eachDetail)=>{

            if(eachDetail._nm === true){
                NMCounter++;
                newMember = 'Si';
            } else {
                newMember = 'No';
            };

            if(eachDetail._renew === true){
                RenewsCounter++;
                renewCredential = 'Si';
            } else {
                renewCredential = 'No';
            };

            
            rankAcquire = getBeltName(eachDetail._currentRank, belts);

            counter++;
            return (
                <StyledTableRow 
                    key={eachDetail._id} 
                    onClick={()=>setDetailSelected(eachDetail)}
                    className={getRowClass(eachDetail._id)}
                >
                    <StyledTableCell>
                        {eachDetail._name}
                    </StyledTableCell>
                    <StyledTableCell>
                        {rankAcquire}
                    </StyledTableCell>
                    <StyledTableCell>
                        {newMember}
                    </StyledTableCell>
                    <StyledTableCell>
                        {renewCredential}
                    </StyledTableCell>
                </StyledTableRow>
            )  
        });
        examStudents.current = (`${counter}`);
        examNM.current = (`${NMCounter}`);
        examRenews.current = (`${RenewsCounter}`);
        return rowsToPrint;
    }

    const updateExamStatus = async (newStatus) => {

        let examUpdated = examSelected;
        examUpdated._status = newStatus;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        const detailsUpdated = [];
        for(const eachDetail of examUpdated._details){
            detailsUpdated.push({
                id: eachDetail._id,
                idExam: eachDetail._idExam,
                idStudent: eachDetail._idStudent,
                name:eachDetail._name,
                currentRank: eachDetail._currentRank,
                nm: eachDetail._nm,
                renew: eachDetail._renew,
                nationalAtaNumber: eachDetail._nationalAtaNumber
            });
        }

        let examData = {
            id: examUpdated._id,
            idAcademy: examUpdated._idAcademy,
            idInstructor: examUpdated._idInstructor,
            instructorName: examUpdated._instructorName,
            date: examUpdated._date,
            authorized: examUpdated._authorized,
            status: examUpdated._status,
            blackBelts: false,
            details: detailsUpdated
        }

        try {
            const url = serverUrl + "/rest/exam";
            const serverResponse =  await axios.put(url, examData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                updateExam(examUpdated);
            } else {
                console.log("Error al actualizar el status de examen");
            }  
        } catch (error) {
            console.log("Error en el post: ", error);
            //error.response.status  de aca mandarlo al login si se vence el token por ejemplo (usar if con 
            //numero de error)
        }  

    } 

    //From here I used Material UI

    const StyledTableCell = withStyles((theme) => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: 17
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
          minWidth: 200,
        },
      });

   
    const classes = useStyles();

    return (
        <>
            <div className="expirationDate">
                <h2>Fecha de Examen: {!(examSelected == null) ? examSelected._date.toLocaleDateString() : null}</h2>
            </div>
            <TableContainer component={Paper} className="cannonScroll">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Nombre</StyledTableCell>
                            <StyledTableCell>Rango al que pasa</StyledTableCell>
                            <StyledTableCell className='cellWidth'>Nuevo miembro</StyledTableCell>
                            <StyledTableCell className='cellWidth'>Renueva Carnet</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="cannonTableBody">
                        {renderExam()}
                    </TableBody>  
                </Table>  
            </TableContainer>
            <h1>Alumnos: {examStudents.current}  |  Nuevos Miembros: {examNM.current}  |  Renovaciones: {examRenews.current}</h1>
            <h2 className='stateSelection'>Seleccione Estado del Examen</h2>
            <Select 
                className='selFontTotals'
                value={examSelected ? {value: examSelected._status, label: examSelected._status} : null}
                options={[
                    {value: 'Borrador', label: 'Borrador'},
                    {value: 'En Proceso', label: 'En Proceso'},
                    {value: 'Cerrado', label: 'Cerrado'}
                ]}
                onChange={(option) => updateExamStatus(option.value)} 
            />
        </>
     );
}
 
export default ExamTable;