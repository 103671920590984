import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import PreferencesContext from '../context/preferences/PreferencesContext';
import {serverUrl} from '../Helper';
import axios from 'axios';
import Button from '@material-ui/core/Button';

import AlertContext from '../context/alerts/AlertContext';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

const Preferences = () => {

    const classes = useStyles();

    const {setAlert} = useContext(AlertContext);
    
    const {
        scDuration,
        scPrice,
        cannonPrice,
        oneSExamPrice,
        setSCDuration, 
        setSCPrice, 
        setCannonPrice, 
        setoneSExamPrice
    } = useContext(PreferencesContext);

    const updateSCDuration = async() => {

        if(scDuration === '') {
            return setAlert({
                show: true,
                msg: 'Agregue una Duración!',
                type: 'error'
            });
        }

        const currentYear = new Date().getFullYear();

        const dataSent = {
            scDuration: scDuration
        };

        try {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentSCDuration/" + currentYear;
            const response =  await axios.put(url, dataSent, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if (data.ok) {
                setAlert({
                    show: true,
                    msg: 'Se actualizó correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
        } catch (error) {
            console.log(error.response); 
        }  
    }

    const updateSCPrice = async() => {

        if(scPrice === '') {
            return setAlert({
                show: true,
                msg: 'Agregue un Precio!',
                type: 'error'
            });
        }

        const currentYear = new Date().getFullYear();

        const dataSent = {
            scPrice
        };

        try {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentSCPrice/" + currentYear;
            const response =  await axios.put(url, dataSent, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if (data.ok) {
                setAlert({
                    show: true,
                    msg: 'Se actualizó correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
        } catch (error) {
            console.log(error.response); 
        }  
    }

    const updateCannonPrice = async() => {

        if(cannonPrice === '') {
            return setAlert({
                show: true,
                msg: 'Agregue un Precio!',
                type: 'error'
            });
        }

        const currentYear = new Date().getFullYear();

        const dataSent = {
            cannonPrice
        };

        try {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentCannonPrice/" + currentYear;
            const response =  await axios.put(url, dataSent, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if (data.ok) {
                setAlert({
                    show: true,
                    msg: 'Se actualizó correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
        } catch (error) {
            console.log(error.response); 
        }  
    }

    const updateExamPrice = async() => {

        if(oneSExamPrice === '') {
            return setAlert({
                show: true,
                msg: 'Agregue un Precio!',
                type: 'error'
            });
        }

        const currentYear = new Date().getFullYear();

        const dataSent = {
            examPrice: oneSExamPrice
        };

        try {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/currentExamPrice/" + currentYear;
            const response =  await axios.put(url, dataSent, {headers: {'Authorization': currentToken}});
            const data = response.data;

            if (data.ok) {
                setAlert({
                    show: true,
                    msg: 'Se actualizó correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
        } catch (error) {
            console.log(error.response); 
        }  
    }

    return ( 
        <div className={classes.root}>
            <Grid container className="cannonMainContainer">
                <Grid item xs={12} className=''>
                    <h1 className='preferencesTitle'>Preferencias</h1>
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item xs={12} sm={3} className='preferencesFirstRow'>
                    <TextField type='number' value={scDuration} onChange={(e)=>setSCDuration(e.target.value)} id="standard-basic" className='preferencesTxF' InputLabelProps={{style: {fontSize: 30}}} label="Duración: School Charter"/>
                    <Button className='preferencesBtns' variant="contained" color="primary" onClick={()=>updateSCDuration()}>Actualizar</Button>
                </Grid>
                <Grid xs={12} sm={3} item className='preferencesFirstRow'>
                    <TextField type='number' value={scPrice} onChange={(e)=>setSCPrice(e.target.value)} className='preferencesTxF' InputLabelProps={{style: {fontSize: 30}}} label="Precio: School Charter" />
                    <Button className='preferencesBtns' onClick={()=>updateSCPrice()} variant="contained" color="primary">Actualizar</Button>
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item sm={3}></Grid>
                <Grid xs={12} sm={3} item className=''>
                    <TextField type='number' value={cannonPrice} onChange={(e)=>setCannonPrice(e.target.value)} className='preferencesTxF' InputLabelProps={{style: {fontSize: 30}}} label="Precio: Cannon" />
                    <Button className='preferencesBtns' onClick={()=>updateCannonPrice()} variant="contained" color="primary">Actualizar</Button>
                </Grid>
                <Grid xs={12} sm={3} item className=''>
                    <TextField type='number' value={oneSExamPrice} onChange={(e)=>setoneSExamPrice(e.target.value)} className='preferencesTxF' InputLabelProps={{style: {fontSize: 30}}} label="Precio: Examen (p/alumno)" />
                    <Button className='preferencesBtns' onClick={()=>updateExamPrice()} variant="contained" color="primary">Actualizar</Button>
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item sm={12} id='preferencesBottom'></Grid>
            </Grid>
        </div>
     );
}
 
export default Preferences;