import React, {useContext} from 'react';
import AcademyContext from '../context/academies/AcademyContext';
import CannonContext from '../context/cannon/CannonContext';
import CharterContext from '../context/schoolCharter/CharterContext';
import ExamContext from '../context/exam/ExamContext';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../index.css';


const AcademyTable = () => {
  

    
    const academyContext = useContext(AcademyContext);
    const {academies, setAcademySelected, academySelected, academiesLoading} = academyContext;

    const { resetCannonSelected } = useContext(CannonContext);
    const { resetCharterSelected } = useContext(CharterContext);
    const { loading, resetDetailSelected, resetExamSelected } = useContext(ExamContext);

    const eachAcademy = ()=>{
        const mapeo = academies.sort((a,b) => (a._name > b._name) ? 1 : ((b._name > a._name) ? -1 : 0)).map((academy)=>{
            return (
                <StyledTableRow align="left" className={academySelected._id === academy._id ? "mousePointer rowSelected" : "mousePointer"} key={academy._id} onClick={(e)=>selectAcademy(academy, e)}>
                    <StyledTableCell >{academy._name}</StyledTableCell >
                </StyledTableRow>
            );
        })
        return mapeo; 
    }

    const selectAcademy = (academy, e) => {
      if(loading) return;
      resetCannonSelected();
      resetCharterSelected();
      resetDetailSelected();
      resetExamSelected();
        
      /* e.target.className += "rowSelected"; */
      setAcademySelected(academy);
    }

    //From here I used Material UI

    const StyledTableCell = withStyles((theme) => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: 25
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const useStyles = makeStyles({
        tableContainer: {
        overflowX: 'hidden',
        }
    });

   
    const classes = useStyles();

    return ( 
      <TableContainer className={classes.tableContainer} id='academiesContainer' component={Paper}>
          <Table className={classes.table} aria-label="customized table" id='academyTable'>
              <TableHead>
                  <TableRow>
                      <StyledTableCell id="academyName">Academia</StyledTableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {academiesLoading ? <CircularProgress size={80} className="tableLoading" /> : eachAcademy()}
              </TableBody>
          </Table>
      </TableContainer>
     );
}

 
export default AcademyTable;
