import React, {useReducer, useCallback} from 'react';
import ExamContext from './ExamContext';
import ExamReducer from './ExamReducer';
import {
    SET_EXAMS_LOADING,
    SET_EXAMS,  
    ADD_EXAM, 
    ADD_EXAM_DETAIL, 
    UPDATE_EXAM,
    UPDATE_EXAM_DETAIL,  
    DELETE_EXAM, 
    DEL_EXAM_DETAIL, 
    SET_EXAM_SELECTED,
    SET_SELECTED_DETAIL,
    SET_FILES_GENERATED,

    SET_INSTRUCTION_EXAMS,
    ADD_INSTRUCTION_EXAM,
    ADD_INSTRUCTION_EXAM_DETAIL,
    UPDATE_INSTRUCTION_EXAM,
    UPDATE_INSTRUCTION_EXAM_DETAIL,
    DELETE_INSTRUCTION_EXAM,
    DEL_INSTRUCTION_EXAM_DETAIL,
    SET_INSTRUCTION_EXAM_SELECTED,
    SET_SELECTED_INSTRUCTION_DETAIL,
    
} from '../../types';

const ExamState = (props) => {

    const initialState = {
        loading: false,
        exams: [],
        examSelected: {
            _id: 0,
            _idAcademy: 0,
            _idInstructor: 0,
            _instructorName: '',
            _date: new Date(),
            _authorized: true,
            _status: 'Borrador',
            _blackBelts: false,
            _details: [],
        },
        detailSelected: {
            _id: 0,
            _idExam: 0,
            _idStudent: 0,
            _name: '',
            _currentRank: 1,
            _nm: false,
            _renew: false,
            _nationalAtaNumber: 0
        },
        filesGenerated: false,

        instructionExams: [],
        instructionExamSelected: {
            _id: 0,
            _date: new Date(),
            _status: 'Borrador',
            _details: [],
        },
        instructionDetailSelected: {
            _id: 0,
            _idAcademy: 0,
            _idInstructionExam: 0,
            _calification: 0,
            _idInstructor: 0,
            _name: '',
            _currentRank: 1,
        }
    }

    //en el useReducer se ponen como parametros el reducer y el state inicial. 
    // state y dispatch se ocupan siempre por convención pero es lo mismo que cualquier state
    const [state, dispatch] = useReducer(ExamReducer, initialState);

    //Exam Functions

    const setLoading = useCallback((loading) => {
        dispatch({
            type: SET_EXAMS_LOADING,
            payload: loading
        });
    },[]);

    const setExams = useCallback((exams) => {
        dispatch({
            type: SET_EXAMS,
            payload: exams
        });
    },[]);

    const addExam = (exam) => {
        dispatch({
         type: ADD_EXAM,
         payload: exam
        });
    }
     
    const addExamDetail = (detail) => {
        dispatch({
            type: ADD_EXAM_DETAIL,
            payload: detail
        });
    }

    const updateExam = (exam) => {
        dispatch({
         type: UPDATE_EXAM,
         payload: exam
        });
     } 

    const updateExamDetail = (detail) => {
        dispatch({
            type: UPDATE_EXAM_DETAIL,
            payload: detail
        });
    }

    const deleteExam = (exam) => {
        dispatch({
            type: DELETE_EXAM,
            payload: exam
        });
        resetExamSelected();
    }

    const delExamDetail = (detail) => {
        dispatch({
            type: DEL_EXAM_DETAIL,
            payload: detail
        });
    }

    const setExamSelected = useCallback((exam) => {
        dispatch({
            type: SET_EXAM_SELECTED,
            payload: exam
        });
    },[]);

    const setDetailSelected = (detail) => {
       dispatch({
        type: SET_SELECTED_DETAIL,
        payload: detail
       });
    }

    const resetExamSelected = useCallback(() => {
        dispatch({
            type: SET_EXAM_SELECTED,
            payload: {
                _id: 0,
                _idAcademy: 0,
                _idInstructor: 0,
                _instructorName: '',
                _date: new Date(),
                _authorized: true,
                _status: 'Borrador',
                _details: [],
            }
        });
    },[]);

    const resetDetailSelected = () => {
        dispatch({
            type: SET_SELECTED_DETAIL,
            payload: {
                _id: 0,
                _idExam: 0,
                _idStudent: 0,
                _name: '',
                _currentRank: 1,
                _nm: false,
                _renew: false,
                _nationalAtaNumber: 0
            }
        });
     }

    const setFilesGenerated = (filesGenerated) => {
        dispatch({
            type: SET_FILES_GENERATED,
            payload: filesGenerated
        });
    }


    //Instruction Exams Functions

    const setInstructionExams = useCallback((exams) => {
        dispatch({
            type: SET_INSTRUCTION_EXAMS,
            payload: exams
        });
    },[]);

    const addInstructionExam = (exam) => {
        dispatch({
         type: ADD_INSTRUCTION_EXAM,
         payload: exam
        });
    }
     
    const addInstructionExamDetail = (detail) => {
        dispatch({
            type: ADD_INSTRUCTION_EXAM_DETAIL,
            payload: detail
        });
    }

    const updateInstructionExam = (exam) => {
        dispatch({
         type: UPDATE_INSTRUCTION_EXAM,
         payload: exam
        });
     } 

    const updateInstructionExamDetail = (detail) => {
        dispatch({
            type: UPDATE_INSTRUCTION_EXAM_DETAIL,
            payload: detail
        });
    }

    const deleteInstructionExam = (exam) => {
        dispatch({
            type: DELETE_INSTRUCTION_EXAM,
            payload: exam
        });
        resetExamSelected();
    }

    const delInstructionExamDetail = (detail) => {
        dispatch({
            type: DEL_INSTRUCTION_EXAM_DETAIL,
            payload: detail
        });
    }
     
    const setInstructionExamSelected = useCallback((exam) => {
        dispatch({
            type: SET_INSTRUCTION_EXAM_SELECTED,
            payload: exam
        });
    },[]);

    const setInstructionDetailSelected = (detail) => {
       dispatch({
        type: SET_SELECTED_INSTRUCTION_DETAIL,
        payload: detail
       });
    }

    const resetInstructionExamSelected = useCallback(() => {
        dispatch({
            type: SET_INSTRUCTION_EXAM_SELECTED,
            payload: {
                _id: 0,
                _date: new Date(),
                _status: 'Borrador',
                _details: [],
            }
        });
    },[]);

    const resetInstructionDetailSelected = () => {
        dispatch({
            type: SET_SELECTED_INSTRUCTION_DETAIL,
            payload: {
                _id: 0,
                _idAcademy: 0,
                _idInstructionExam: 0,
                _calification: 0,
                _idInstructor: 0,
                _name: '',
                _currentRank: 1,
            }
        });
     }

    return (
        <ExamContext.Provider
            value={{
                loading: state.loading,
                exams: state.exams,
                examSelected: state.examSelected,
                detailSelected: state.detailSelected,
                filesGenerated: state.filesGenerated,
                instructionExams: state.instructionExams,
                instructionExamSelected: state.instructionExamSelected,
                instructionDetailSelected: state.instructionDetailSelected,
                
                setLoading,
                setExams,
                addExam,
                addExamDetail,
                updateExam,
                updateExamDetail,
                deleteExam,
                delExamDetail,
                setExamSelected,
                setDetailSelected,
                resetExamSelected,
                resetDetailSelected,
                setFilesGenerated,
                
                
                setInstructionExams,
                addInstructionExam,
                addInstructionExamDetail,
                updateInstructionExam,
                updateInstructionExamDetail,
                deleteInstructionExam,
                delInstructionExamDetail,
                setInstructionExamSelected,
                setInstructionDetailSelected,
                resetInstructionExamSelected,
                resetInstructionDetailSelected
            }}
        >
            {props.children}
        </ExamContext.Provider>
    )
}
 
export default ExamState; 