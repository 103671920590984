import React, {useContext} from 'react';
import ExamContext from '../../context/exam/ExamContext';
import { useHistory } from 'react-router-dom';
import { serverUrl } from '../../Helper';
import axios from 'axios';
import AlertContext from '../../context/alerts/AlertContext';

import Swal from 'sweetalert2';

const InstructionExamsCrud = () => {
    const {setAlert} = useContext(AlertContext);

    const examContext = useContext(ExamContext);
    const {instructionExamSelected, setInstructionExamSelected, addInstructionExam, updateInstructionExam, deleteInstructionExam} = examContext;

    const history = useHistory();

    const handleChange = (e)=>{
        if(e.target.name === "_date") {
            let date = Date.parse(e.target.value);
            if(isNaN(date)) return;
            date = new Date(date);

            setInstructionExamSelected({
                ...instructionExamSelected,
                [e.target.name]: date
            })
        } else {
            setInstructionExamSelected({
                ...instructionExamSelected,
                [e.target.name]: e.target.value
            })
        }
    }

    const newInstructionExam = async (e) => {
        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere agregar un Examen?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;
           
        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        //Add 1 day to the date
        const UTCDate = new Date(instructionExamSelected._date.toISOString().substring(0,10)) ;
        
        UTCDate.setDate(UTCDate.getDate() + 1);
        
        let examData = {
            date: UTCDate,
            status: 'Borrador',
            details: []
        }

        try {
            const url = serverUrl + "/rest/instructionExam";
            const serverResponse =  await axios.post(url, examData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;
            if(data.ok){
                const newExam = data.instructionExam;
                newExam._date = Date.parse(newExam._date);
                newExam._date = new Date(newExam._date);

                addInstructionExam(newExam);

                setAlert({
                    show: true,
                    msg: 'Se agregó el examen correctamente!',
                    type: 'success'
                });
            } else {
                console.error("Error al crear nuevo examen");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
        } catch (error) {
            console.error("Error en el post: ", error);
        }  
    }

    const delInstructionExam = async (e) => {
        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere eliminar este Examen?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        if(instructionExamSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un examen para eliminarlo!',
                type: 'error'
            });
        }

        try {
            const url = serverUrl + "/rest/instructionExam/" + instructionExamSelected._id;
            const serverResponse =  await axios.delete(url, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                deleteInstructionExam(instructionExamSelected);
                setAlert({
                    show: true,
                    msg: 'Se borró el examen correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error al eliminar Examen");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
            
        } catch (error) {
            console.log("Error en el post: ", error);
        }  
        
    }

    //updates exams
    const instructionExamUpdate = async (e) => {
        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere actualizar este Examen?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        })

        if(!popUpResult.value) return;
        
        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }
        
        if(instructionExamSelected._id === 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Examen para actualizarlo!',
                type: 'error'
            });
        }

        //Add 1 day to the date
        const UTCDate = new Date(instructionExamSelected._date.toISOString().substring(0,10)) ;
        
        UTCDate.setDate(UTCDate.getDate() + 1);

        let detailsToSend = [];

        if(instructionExamSelected._details) {

            detailsToSend = instructionExamSelected._details.map((eachDetail) => {
                return {
                    id: eachDetail._id,
                    idInstructionExam: eachDetail._idInstructionExam,
                    calification: eachDetail._calification,
                    idInstructor: eachDetail._idInstructor,
                    name: eachDetail._name,
                    currentRank: eachDetail._currentRank,
                }
            });
        }

        let examData = {
            id: instructionExamSelected._id,
            date: UTCDate,
            status: instructionExamSelected._status,
            details: detailsToSend
        }


        try {
            const url = serverUrl + "/rest/instructionExam";
            const serverResponse =  await axios.put(url, examData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            const examUpdated = data.instructionExam; 

            examUpdated._date = Date.parse(examUpdated._date);
            examUpdated._date = new Date(examUpdated._date);

            if(data.ok){
                updateInstructionExam(examUpdated);
                setAlert({
                    show: true,
                    msg: 'Se actualizo el examen correctamente!',
                    type: 'success'
                });
            } else {
                console.log("Error al modificar examen");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }
        } catch (error) {
            console.error("Error en el post: ", error);
        }  
    }

    return (
        <form id="examCrudMainContainer">
            <div id='newExamContainer'>
                <label className="crudMainTitle">Creación/Edición de Examen</label>
                <div className="mb-3 cannonformItem">
                    <label htmlFor="exampleInputPassword1" className="form-label">Fecha</label>
                    <input value={instructionExamSelected._date.toISOString().substring(0,10)} name="_date" onChange={handleChange} type="date" className="form-control inputFonts" />
                </div>
            
                <div className="crudBtnsPosition cannonformItem">
                    <button type="button" className="btn btn-primary btnCrearExamen btnFont" onClick={(e)=>newInstructionExam(e)}>Crear Examen</button>
                    <button type="button" className="btn btn-primary btnEliminar btnFont" onClick={(e)=>delInstructionExam(e)}>Eliminar</button>
                    <button type="button" className="btn btn-primary btnActualizar btnFont" onClick={(e)=>instructionExamUpdate(e)}>Actualizar</button>
                </div>
            </div>
        </form>
    );
}

export default InstructionExamsCrud;