import React, {useState, Fragment, useContext} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {serverUrl} from '../../Helper';
import AlertContext from '../../context/alerts/AlertContext';

const Login = () => {

    const {setAlert} = useContext(AlertContext);

    const history = useHistory();

    //State para iniciar sesión
    const [user, saveUser] = useState({
        email: "",
        password: ""
    });

    //Extraer usuario
    const {email, password} = user;

    const onChange = e => {
        saveUser({
            ...user,
            [e.target.name]:e.target.value
        })
    }

    //Cuando el usuario quiere iniciar sesión
    const onSubmit = async e => {
        e.preventDefault();
        
        //Validar que no haya campos vacios
        if(email !== '' && password !== ''){
            const userData = {
                name: email,
                password
            }
            try {
                const url = serverUrl + "/rest/login";
                const loginResponse = await axios.post(url, userData);
    
                if(loginResponse?.data?.ok){
                    localStorage.setItem("jwt-SAMS", loginResponse.data.token);

                    history.push("/main");
                } else {
                    console.log(loginResponse.data);
                }
            } catch(error) {
                setAlert({
                    show: true,
                    msg: 'Usuario y/o Contraseña Incorrectos!',
                    type: 'error'
                });
            }
            

        } else {
            setAlert({
                show: true,
                msg: 'Todos los campos son obligatorios!',
                type: 'error'
            });
        }

        //Pasarlo al Action
    }

     

    return ( 
        <Fragment>
            <div>
                <h1 className="loginGreet">SAMS</h1>
                <div className="form-usuario">
                    <div className="contenedor-form sombra-dark">
                        <h1>Iniciar Sesión</h1>
                        <form
                            onSubmit={onSubmit}
                        >
                            <div className="campo-form">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Escribe tu Email"
                                    value={email}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="campo-form">
                                <label htmlFor="password">Contraseña</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Escribe tu Contraseña"
                                    value={password}
                                    onChange={onChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="campo-form">
                                <input 
                                    type="submit"
                                    className="loginBtn btn btn-primario btn-block"
                                    value="Ingresar"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
     );
}
 
export default Login;
