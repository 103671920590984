import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Login from './components/auth/login';
import Main from './components/Main';
import AlertBox from './components/AlertBox';
/* import './css/mainGrid.css';*/
import './css/cannon.css';
import './css/exams.css';
import './css/preferences.css';
import './css/schoolCharter.css';
import './css/animations.css';  
import '@sweetalert2/theme-dark/dark.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import AcademyState from './context/academies/AcademyState';
import CannonState from './context/cannon/CannonState';
import CharterState from './context/schoolCharter/CharterState';
import ExamState from './context/exam/ExamState';
import BeltsState from './context/belts/BeltsState';
import PreferencesState from './context/preferences/PreferencesState';
import AlertState from './context/alerts/AlertState';
import UserState from './context/users/UserState';
//import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
//import { ThemeProvider } from "@material-ui/core/styles";

//const theme = createMuiTheme();

function App() {
  return (
    <UserState>
      <AcademyState >
        <CannonState>
          <CharterState>
            <ExamState>
              <BeltsState>
                <PreferencesState>
                  <AlertState>
                    <div className="mainContainer">
                      <div className="content">
                      <CssBaseline />
                      <AlertBox />
                        <Router>
                          <Switch>
                              <Route exact path="/" component={Login} />
                              <Route exact path="/main" component={Main} /> 
                          </Switch>
                        </Router>
                      </div>
                    </div>
                  </AlertState>
                </PreferencesState>
              </BeltsState>
            </ExamState>
          </CharterState>
        </CannonState>
      </AcademyState>
    </UserState>
  );
}

export default App;
