import React, {useReducer, useCallback} from 'react';
import PreferencesContext from './PreferencesContext';
import PreferencesReducer from './PreferencesReducer';
import {SET_SC_DURATION, SET_SC_PRICE, SET_CANNON_PRICE, SET_ONES_PRICE} from '../../types';

const PreferencesState = (props) => {

    const initialState = {
        scDuration: 0,
        scPrice: 0,
        cannonPrice: 0,
        oneSExamPrice: 0
    }

    //en el useReducer se ponen como parametros el reducer y el state inicial. 
    // state y dispatch se ocupan siempre por convención pero es lo mismo que cualquier state
    const [state, dispatch] = useReducer(PreferencesReducer, initialState);

    const setSCDuration = useCallback((duration) => {
        dispatch({
            type: SET_SC_DURATION,
            payload: duration
        });
    },[]);

    const setSCPrice = useCallback((price) => {
        dispatch({
            type: SET_SC_PRICE,
            payload: price
        });
    },[]);

    const setCannonPrice = useCallback((price) => {
        dispatch({
            type: SET_CANNON_PRICE,
            payload: price
        });
    },[]);

    const setoneSExamPrice = useCallback((price) => {
        dispatch({
            type: SET_ONES_PRICE,
            payload: price
        });
    },[]);

    return (
        <PreferencesContext.Provider
            value={{
                scDuration: state.scDuration,
                scPrice: state.scPrice,
                cannonPrice: state.cannonPrice,
                oneSExamPrice: state.oneSExamPrice,
                setSCDuration,
                setSCPrice,
                setCannonPrice,
                setoneSExamPrice
            }}
        >
            {props.children}
        </PreferencesContext.Provider>
    )
}
 
export default PreferencesState; 