import React, {useReducer, useCallback} from 'react';
import AcademyContext from './AcademyContext';
import AcademyReducer from './AcademyReducer';
import {SET_ACADEMIES, SET_ACADEMY_SELECTED} from '../../types';



const AcademyState = (props) => {

    const initialState = {
        academies: [],
        academySelected: {},
        academiesLoading: true
    }

    //en el useReducer se ponen como parametros el reducer y el state inicial. 
    // state y dispatch se ocupan siempre por convención pero es lo mismo que cualquier state
    const [state, dispatch] = useReducer(AcademyReducer, initialState);

    const setAcademies = useCallback((info) => {
        //A dispatch se le pasa el type y si es necesario el payload(info con la cual modifica el state)
        dispatch({
            type: SET_ACADEMIES,
            payload: info
        });
    },[]);

    const setAcademySelected = (info) => {
        dispatch({
            type: SET_ACADEMY_SELECTED,
            payload: info
        });
    }



    return (
        <AcademyContext.Provider
            value={{
                academies: state.academies,
                academySelected: state.academySelected,
                academiesLoading: state.academiesLoading,
                setAcademies, 
                setAcademySelected
            }}
        >
            {props.children}
        </AcademyContext.Provider>
    )
}
 
export default AcademyState; 