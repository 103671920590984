import React, {useContext, Fragment, useState, useEffect} from 'react';
import CannonContext from '../../context/cannon/CannonContext';
import AcademyContext from '../../context/academies/AcademyContext';
import Select from 'react-select';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const CannonTable = () => {

    const cannonContext = useContext(CannonContext);
    const {cannons, setCannonSelected, cannonSelected} = cannonContext;

    const academyContext = useContext(AcademyContext);
    const {academySelected} = academyContext;

    const [yearSelected, setYearSelected] = useState('Seleccione un año'); 

    const [currentCannons, setCurrentCannons] = useState([]);

    useEffect(() => {

        if(academySelected._id) {
            let filteredCannons = cannons.map((eachCannon) => {
                if (eachCannon._idAcademy === academySelected._id) {
                    return eachCannon;
                }
                return undefined;
            });
            filteredCannons = filteredCannons.filter((eachCannon)=>{
                return eachCannon !== undefined;
            })
            setCurrentCannons(filteredCannons);
        }
    }, [cannons, academySelected, setCurrentCannons]);
    
    const getRowClass = (id) => {

        let rowClass = "mousePointer";

        //let currentDate = new Date();

        if (cannonSelected._id === id) {
            return rowClass + " rowSelected";    
        } 

        /* if (currentDate > expDate && !isClosed) {
            return rowClass + " expiredRow";
        } 

        currentDate.setMonth(currentDate.getMonth() + 1);
        
        if (currentDate > expDate && !isClosed) {
            return rowClass + " warningRow";
        } */

        return rowClass;
    }
    
    const renderCannons = ()=> {

        if(!currentCannons || currentCannons.length === 0) {

            return <tr><td>Nada para mostrar</td></tr>  
       
        } else {
            
            let rowsToPrint = currentCannons.map((eachCannon)=>{

                if(eachCannon._exp.getFullYear() === Number.parseInt(yearSelected)){

                        let isClosed = "No";
                        if(eachCannon._closed) {
                            isClosed = "Si"; 
                        } 

                        return (
                            <StyledTableRow 
                                key={eachCannon._id} 
                                onClick={()=>setCannonSelected(eachCannon)}
                                className={getRowClass(eachCannon._id)}
                            >
                                <StyledTableCell>
                                    {eachCannon._amount}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {eachCannon._date.toLocaleDateString()}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isClosed}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {eachCannon._exp.toLocaleDateString()}
                                </StyledTableCell>
                            </StyledTableRow>
                        )
                } 
                return null;
            });
            return rowsToPrint;
        }    
    }

    const yearOptions = () => {

        const yearsObj = {};

        const years = currentCannons.map((eachCannon)=> {

            const year = eachCannon._exp.getFullYear();

            if(!yearsObj[year]){
                yearsObj[year] = year;
                return Number.parseInt(year);    
            }
            return undefined;
        }).filter(eachYear => eachYear !== undefined);
        years.sort();
        const options = years.map((eachYear)=>{
            return {
                value: eachYear, label: eachYear
            }   
        });
        return options;
    }

    //From here I used Material UI

    const StyledTableCell = withStyles((theme) => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: 17
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
          minWidth: 200,
        },
      });

   
    const classes = useStyles();

    return ( 
        <Fragment>
            <div className="expirationDate">
                <label className='selFontTotals' htmlFor="years">Año de vencimiento:</label>
                <Select 
                    className='selFontTotals'
                    value={{value: yearSelected, label: yearSelected}} 
                    options={yearOptions()} 
                    onChange={(option) => setYearSelected(option.value)} 
                />
            </div>
            <TableContainer component={Paper} id='cannonScrollX' className="cannonScroll">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Cantidad</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Cerrado</StyledTableCell>
                            <StyledTableCell>Vencimiento</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="cannonTableBody">
                        {renderCannons()}
                    </TableBody>  
                </Table>  
            </TableContainer>
        </Fragment>
     );
}
 
export default CannonTable;