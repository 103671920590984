import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 17
    },
    body: {
      fontSize: 14,
      minWidth: 160
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);




const AcademyStatistics = ({ academies, belts }) => {
    

    const getAcademiesBelts = () => {
        if(belts.length === 0) return null;
        return academies.map(eachAcademy => {
            const beltsKeys = Object.keys(eachAcademy.statistics).filter(eachKey => eachKey.match(new RegExp("^b([0-9]{1,2})$","i")));
            return (
                <StyledTableRow align="left" key={eachAcademy.academy}>
                    <StyledTableCell>{eachAcademy.academyName}</StyledTableCell>
                    {beltsKeys.map(eachKey => <StyledTableCell key={eachKey}>{eachAcademy.statistics[eachKey]}</StyledTableCell>)}
                </StyledTableRow>
            );
        });
    }


    const getAcademiesTotalsBelts = () => {
        if(belts.length === 0) return null;
            const totals = {};
            academies.map((eachAcademy) => {
                const beltsKeys = Object.keys(eachAcademy.statistics).filter(eachKey => eachKey.match(new RegExp("^b([0-9]{1,2})$","i")));
                beltsKeys.map(eachKey => {
                    const id = Number(eachKey.substring(1));
                    const correspondingBelt = belts.find(eachBelt => eachBelt._id === id);
                    totals[eachKey] = (totals[eachKey] ? 
                        {
                            ...totals[eachKey],
                            amount: totals[eachKey].amount + Number(eachAcademy.statistics[eachKey])
                        } 
                        : 
                        { id, name: correspondingBelt._name, amount: Number(eachAcademy.statistics[eachKey])});
                    return null;
                });
                return null;
            });
            return Object.keys(totals).map(eachKey => {
                return (
                    <StyledTableRow align="left" key={totals[eachKey].id}>
                        <StyledTableCell>{totals[eachKey].name}</StyledTableCell>
                        <StyledTableCell>{totals[eachKey].amount}</StyledTableCell>
                    </StyledTableRow>
                );
            });
    }

    
    return ( 
        <Grid container item xs={12}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <h1>Rangos Totales</h1>
                <TableContainer className="statisticsTable" component={Paper}>
                    <Table  aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Rango</StyledTableCell>
                                <StyledTableCell id="academyName">Cantidad</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getAcademiesTotalsBelts()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <h1>Rangos por Academia</h1>
                <TableContainer className="statisticsTable" component={Paper}>
                    <Table  aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Academia</StyledTableCell>
                                {belts.map(eachBelt => {
                                    return (
                                        <StyledTableCell key={eachBelt._id} id="academyName">{eachBelt._name}</StyledTableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getAcademiesBelts()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
 
export default AcademyStatistics;