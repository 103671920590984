import React from 'react'
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table } from '@material-ui/core';
import GeneralStatistics from './GeneralStatistics';
import AcademyStatistics from './AcademyStatistics';
import ExamStatistics from './ExamStatistics';
import StatisticsExamCharts from './StatisticsExamCharts';



const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 25
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
    tableContainer: {
        overflowX: 'hidden',
    },
    interactionBar: {
        marginBottom: '20px'
    }
});



const Statistics = ({ belts, stats }) => {
    const classes = useStyles();

    const getTopTenAcademiesColors = () => {
        return stats?.tops?.topByColorExams?.slice(0,10).map(eachAcademy => {
            return (
                <StyledTableRow align="left" key={eachAcademy.id}>
                    <StyledTableCell >{eachAcademy.name}</StyledTableCell>
                    <StyledTableCell >{eachAcademy.amount}</StyledTableCell>
                </StyledTableRow>
            );
        });
    }

    const getTopTenAcademiesNM = () => {
        return stats?.tops?.topByNMs?.slice(0,10).map(eachAcademy => {
            return (
                <StyledTableRow align="left" key={eachAcademy.id}>
                    <StyledTableCell >{eachAcademy.name}</StyledTableCell>
                    <StyledTableCell >{eachAcademy.amount}</StyledTableCell>
                </StyledTableRow>
            );
        });
    }

    const getTopTenAcademiesBlackB = () => {
        return stats?.tops?.topByBlackExams?.slice(0,10).map(eachAcademy => {
            return (
                <StyledTableRow align="left" key={eachAcademy.id}>
                    <StyledTableCell >{eachAcademy.name}</StyledTableCell>
                    <StyledTableCell >{eachAcademy.amount}</StyledTableCell>
                </StyledTableRow>
            );
        });
    }



    return (
        <Grid container item xs={11}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
                <h1>Reporte Cintos Colores</h1>
                <TableContainer className={classes.tableContainer} id='academiesContainer' component={Paper}>
                    <Table className={classes.table} aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Academia</StyledTableCell>
                                <StyledTableCell>Exámenes</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getTopTenAcademiesColors()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
                <h1>Instructor del año</h1>
                <TableContainer className={classes.tableContainer} id='academiesContainer' component={Paper}>
                    <Table className={classes.table} aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Academia</StyledTableCell>
                                <StyledTableCell>N.M.</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getTopTenAcademiesNM()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
                <h1>Reportes de Cintos Negros</h1>
                <TableContainer className={classes.tableContainer} id='academiesContainer' component={Paper}>
                    <Table className={classes.table} aria-label="customized table" id='academyTable'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell id="academyName">Academia</StyledTableCell>
                                <StyledTableCell>Exámenes</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getTopTenAcademiesBlackB()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {stats?.exams && stats?.totals && 
                <GeneralStatistics 
                    examsColors={stats.exams.totalColorExams}
                    examsBlacks={stats.exams.totalBlackExams}
                    nms={stats.exams.totalNMs}
                    students={stats.totals.students}
                    instructors={stats.totals.instructors}
                    avgAge={stats.totals.avgAge}
                    males={stats.totals.males}
                    females={stats.totals.females}
                />
            }
            {stats?.academies && belts && (
                <>
                    <AcademyStatistics academies={stats.academies} belts={belts} />
                    <ExamStatistics academies={stats.academies} totalExams={stats.totals?.totalExams} belts={belts} />
                </>
            )}
            <StatisticsExamCharts examsData={stats?.months} />
        </Grid>
    );
}
 
export default Statistics;