import React, {useState, useRef, useEffect, useContext} from 'react';
import Select from 'react-select';
import AcademyContext from '../context/academies/AcademyContext';
import ExamContext from '../context/exam/ExamContext';
import AlertContext from '../context/alerts/AlertContext';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {serverUrl} from '../Helper';
import Swal from 'sweetalert2';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 17
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 200,
    },
});

const InstructorsTable = () => {
    const history = useHistory();
    const { academies } = useContext(AcademyContext);
    const { instructionExamSelected, addInstructionExamDetail, exams } = useContext(ExamContext);
    const {setAlert} = useContext(AlertContext);
    const [academySelected, setAcademySelected] = useState(null);
    const [instructorSelected, setInstructorSelected] = useState(null);
    const [instructors, setInstructors] = useState([]);
    const [instructorsByEachAcademy, setInstructorsByEachAcademy] = useState([]);
    const calificationRef = useRef(null);

    useEffect(() => {
        if(academySelected == null) return;
        const updateStudents = async () => {
            const url = `${serverUrl}/rest/studentsByAcademy/${academySelected._id}`;
            const currentToken = localStorage.getItem("jwt-SAMS");
            const res = await axios.get(url, { headers: {Authorization: currentToken}});
            setInstructors(res.data.instructors);
        }
        updateStudents();
    }, [academySelected]);


    const getRowClass = (id) => {
        let rowClass = "mousePointer";
        if (instructorSelected?.idinstructor === id) {
            return rowClass + " rowSelected";    
        } 
        return rowClass;
    }


    const addInstructorToExam = async () => {
        if(instructorSelected?.idinstructor <= 0 || instructorSelected == null) return;

        const popUpResult = await Swal.fire({
            title: 'Esta seguro que quiere agregar este instructor?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No'
        });

        if(!popUpResult.value) return;

        if(instructionExamSelected._id <= 0) {
            return setAlert({
                show: true,
                msg: 'Seleccione un Examen en donde agregar el Instructor!',
                type: 'error'
            });
        }

        if(calificationRef.current?.value === '' || isNaN(calificationRef.current?.value)) {
            return setAlert({
                show: true,
                msg: 'Ingrese una calificación para agregar el Instructor!',
                type: 'error'
            });
        }

        const currentToken = localStorage.getItem("jwt-SAMS");

        if(!currentToken) {
            history.push("/");
        }

        let detailData = {
            detail:{
                id: 0,
                idAcademy: academySelected._id,
                idInstructionExam: instructionExamSelected._id,
                calification: calificationRef.current?.value,
                idInstructor: instructorSelected.idinstructor,
                name: instructorSelected.name,
                currentRank:  instructorSelected.idrank
            }
        }
        try {
            const url = serverUrl + "/rest/instructionExamDetail";
            const serverResponse =  await axios.post(url, detailData, {headers: {'Authorization': currentToken}});
            const data = serverResponse.data;

            if(data.ok){
                addInstructionExamDetail(data.instructionDetail);
                setAlert({
                    show: true,
                    msg: 'Se agregó el instructor correctamente!',
                    type: 'success'
                });
            } else {
                console.error("Error al agregar instructor");
                setAlert({
                    show: true,
                    msg: 'Ocurrió un error!',
                    type: 'error'
                });
            }  
            
        } catch (error) {
            console.error("Error en el post: ", error);
        }
    }

    useEffect(() => {
        let instructorsByAcademy = 0;

        const getInstructorsforAcademy = async () => {
            const currentToken = localStorage.getItem("jwt-SAMS");
            const url = serverUrl + "/rest/instructorsAcademies";
            const res =  await axios.get(url, {headers: {'Authorization': currentToken}});
            const data = res.data;
            
            if(data.ok){
                instructorsByAcademy = data.academies;
                setInstructorsByEachAcademy(instructorsByAcademy);
            } else {
                console.log("Error al traer instructores");
            } 
        } 
        getInstructorsforAcademy();
    }, []);
    
    const getInstructorExams = (dni)=> {
        let amountOfStudents = 0;
        if (!instructorsByEachAcademy || dni === 0) return 0;   
        const academiesIds = getAcademiesIds(dni);

        for (const eachExam of exams) {
            if(academiesIds.includes(eachExam._idAcademy) && eachExam._status === 'Cerrado'){
                amountOfStudents += eachExam._details.length;
            }
        }
        return amountOfStudents;
    }
    
    const getAcademiesIds = (dni)=> {
        let academiesIds = [];
        for (const eachAcademy of instructorsByEachAcademy) {
            for (const eachInstructor of eachAcademy.instructors) {
                if(dni === eachInstructor.dni) {
                    academiesIds.push(eachAcademy.idAcademy);
                    break;
                }                    
            }
        }
        return academiesIds;
    }  

    const classes = useStyles();
    return (
        <Grid container item xs={12} sm={12} className="BBStudentsSection">
            <Grid container item xs={12} sm={12}>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={2}>
                    <Select 
                        className='selFontTotals studentsSelect'
                        value={academySelected ? {value: academySelected, label: academySelected._name} : null}
                        options={
                            academies.map(eachAcademy => {
                                return {value: eachAcademy, label: eachAcademy._name}
                            })
                        }
                        onChange={(option) => setAcademySelected(option.value)} 
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <input type="number" className='emailInput form-control inputFonts marks' ref={calificationRef} placeholder="Calificación" />
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
                <Grid className='btnCrearGrid' item xs={12} sm={2}>
                    <button type="button" id='agregarABtn' className="btn btn-primary" onClick={() => addInstructorToExam()}>Agregar Instructor</button>
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} className="BBStudentsSectionTables">
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={8}>
                    <h2>Instructores</h2>
                    <TableContainer component={Paper} className="cannonScroll">
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Nombre</StyledTableCell>
                                    <StyledTableCell>Edad</StyledTableCell>
                                    <StyledTableCell>Collar</StyledTableCell>
                                    <StyledTableCell>Cantidad de Exámenes</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="cannonTableBody">
                                {instructors.map(eachInstructor => (
                                    <StyledTableRow 
                                        key={eachInstructor.idinstructor}
                                        onClick={()=> setInstructorSelected(eachInstructor)}
                                        className={getRowClass(eachInstructor.idinstructor)}
                                    >
                                        <StyledTableCell>
                                            {eachInstructor.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachInstructor.age}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {eachInstructor.instructorrank}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {getInstructorExams(Number(eachInstructor.dni))}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                                )}
                            </TableBody>  
                        </Table>  
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
     );
}
 
export default InstructorsTable;