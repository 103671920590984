import React, {useReducer, useCallback} from 'react';
import UserContext from './UserContext';
import UserReducer from './UserReducer';
import {SET_USER} from '../../types';



const UserState = (props) => {

    const initialState = {
        user: {
            _id: 0,
            _name: '',
            _privileges: 0,
            _idPerson: 0
        }
    }

    //en el useReducer se ponen como parametros el reducer y el state inicial. 
    // state y dispatch se ocupan siempre por convención pero es lo mismo que cualquier state
    const [state, dispatch] = useReducer(UserReducer, initialState);

    

    const setUser = useCallback((user) => {
        dispatch({
            type: SET_USER,
            payload: user
        });
    },[]);


    return (
        <UserContext.Provider
            value={{
                user: state.user,
                setUser
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}
 
export default UserState; 