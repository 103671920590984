import {
    SET_SC_DURATION, 
    SET_SC_PRICE, 
    SET_CANNON_PRICE, 
    SET_ONES_PRICE
} from '../../types';


//state es el incial state y action el la información del dispatch
const PreferencesReducer = (state, action) => {

    switch(action.type) {
        case SET_SC_DURATION:
            return {
                ...state,
                scDuration: action.payload
            }
        case SET_SC_PRICE:
            return {
                ...state,
                scPrice: action.payload
            }
        case SET_CANNON_PRICE:
            return {
                ...state,
                cannonPrice: action.payload
            }
        case SET_ONES_PRICE:
            return {
                ...state,
                oneSExamPrice: action.payload
            }
        default: 
            return state;
    }
}

export default PreferencesReducer; 