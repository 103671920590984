import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertContext from '../context/alerts/AlertContext';


const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const AlertBox = () => {

    const { alert, setAlert } = useContext(AlertContext);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
            }
        setAlert({...alert, show: false});
    };

    return ( 
        <Snackbar open={alert.show} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alert.type}>
                {alert.msg}
            </Alert>
        </Snackbar>
     );
}
 
export default AlertBox;
