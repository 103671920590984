import React, {useContext, useState} from 'react';
import CannonContext from '../../context/cannon/CannonContext';
import AcademyContext from '../../context/academies/AcademyContext';
import PreferencesContext from '../../context/preferences/PreferencesContext';
import Select from 'react-select';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CannonTotals = () => {

    const academyContext = useContext(AcademyContext);
    const {academies} = academyContext;

    const cannonContext = useContext(CannonContext);
    const {cannons} = cannonContext;

    const {cannonPrice} = useContext(PreferencesContext);
    

    const [yearSelected, setYearSelected] = useState('Seleccione un año'); 

    const renderTotals = () => {

        let rowsToPrint = [];
        let totalAmount;

        academies.map((eachAcademy) => {
            
            totalAmount = 0;

            if(yearSelected === 'Seleccione un año'){
                return ([]);
            }

            cannons.map((eachCannon) => {
                if (eachCannon._idAcademy === eachAcademy._id && eachCannon._exp.getFullYear() === yearSelected) {
                    totalAmount +=  eachCannon._amount;
                }
                return undefined;
            });

            let cannonTotal = cannonPrice - totalAmount;

            console.log(cannonTotal);
            console.log(cannonTotal <= 0);

            rowsToPrint.push(
                <StyledTableRow key={eachAcademy._id} className={cannonTotal <= 0 ? 'paid' : null}>
                    <StyledTableCell>{eachAcademy._name}</StyledTableCell>
                    <StyledTableCell>{cannonTotal}</StyledTableCell>
                </StyledTableRow>
            );
            return undefined;
        });

        return rowsToPrint;
    }; 

    const yearOptions = () => {

        const yearsObj = {};

        const years = cannons.map((eachCannon)=> {

            const year = eachCannon._exp.getFullYear();

            if(!yearsObj[year]){
                yearsObj[year] = year;
                return Number.parseInt(year);    
            }
            return undefined;
        }).filter(eachYear => eachYear !== undefined);
        years.sort();
        const options = years.map((eachYear)=>{
            return {
                value: eachYear, label: eachYear
            }   
        });
        return options;
    }

    //From here I used Material UI

    const StyledTableCell = withStyles((theme) => ({
        head: {
          color: theme.palette.common.black,
          fontSize: 20,
          fontWeight: 600
          
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
          minWidth: 200,
        },
      });

   
    const classes = useStyles();

    return ( 
        <div>
            <div className="expirationDate">
                <label className='expFontTotals' htmlFor="years">Año de vencimiento:</label>
                <Select 
                    className='selFontTotals'
                    value={{value: yearSelected, label: yearSelected}} 
                    options={yearOptions()} 
                    onChange={(option) => setYearSelected(option.value)} 
                />
            </div>
            <TableContainer component={Paper} className="cannonScroll">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow id='cannonsTotalsHead'>
                            <StyledTableCell>Nombre de Academia</StyledTableCell>
                            <StyledTableCell>Adeuda</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="cannonTableBody">
                        {renderTotals()}
                    </TableBody> 
                </Table>
            </TableContainer>
        </div>
     );
}
 
export default CannonTotals;