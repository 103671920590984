import React, {useContext, useState, useEffect} from 'react';
import Select from 'react-select';
import ExamContext from '../../context/exam/ExamContext';

//Material UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const ExamBBListTable = () => {
    const { loading, exams, examSelected, setExamSelected, resetExamSelected, resetDetailSelected } = useContext(ExamContext);


    const [dateSelected, setDateSelected] = useState('Seleccione una fecha'); 

    const [currentExams, setCurrentExams] = useState([]);

    useEffect(() => {

        let filteredExams = exams.map((eachExam) => {
            if(!(examSelected == null) && examSelected._id > 0 && examSelected._id === eachExam._id){
                setExamSelected(eachExam);
            }
            if (eachExam._blackBelts && eachExam._date.getFullYear() === dateSelected.year && eachExam._date.getMonth() === dateSelected.month - 1) {
                return eachExam;
            }
            return undefined;
        });
        filteredExams = filteredExams.filter((eachExam)=>{
            return eachExam !== undefined;
        });
        setCurrentExams(filteredExams);
        
    }, [exams, setCurrentExams, dateSelected, setExamSelected, examSelected]);
    


    const getRowClass = (id) => {

        let rowClass = "mousePointer";

        if (examSelected._id === id) {
            return rowClass + " rowSelected";    
        } 

        return rowClass;
    }



    const renderExamList = ()=> {
        if(currentExams == null | currentExams.length === 0) {

            return <tr><td>No se encontraron exámenes en esta fecha.</td></tr>;  
       
        } else {
            
            let rowsToPrint = currentExams.map((eachExam)=>{
                return (
                    <StyledTableRow 
                        key={eachExam._id} 
                        onClick={()=> selectExam(eachExam)}
                        className={getRowClass(eachExam._id)}
                    >
                        <StyledTableCell>
                            {eachExam._date.toLocaleDateString()}
                        </StyledTableCell>
                        <StyledTableCell>
                            {eachExam._status}
                        </StyledTableCell>
                    </StyledTableRow>
                )  
            });
            return rowsToPrint;
        }    
    }

    const selectExam = (eachExam) => {
        resetDetailSelected();
        setExamSelected(eachExam)
    }

    const dateOptions = () => {

        const currentYear = new Date().getFullYear();

        let options = [];

        for (let year=currentYear; year >= (currentYear - 5); year--) {

            for(let j=1; j <= 12; j++) {
                let eachOption = {
                    value:{
                        month: j,
                        year
                    },
                    label: j + '/' + year
                }
                options.push(eachOption);
            }
        }
        return options;
    }


    //From here I used Material UI

    const StyledTableCell = withStyles((theme) => ({
        head: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: 17
        },
        body: {
          fontSize: 14,
        },
      }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
            minWidth: 200,
        },
    });

   
    const classes = useStyles();

    const onChangeDate = (option) => {
        setDateSelected(option.value);
        resetExamSelected();
        resetDetailSelected();
    }

    return ( 
        <>
            { loading ? <><CircularProgress size={30} /><h3>Cargando Examenes...</h3></> : null }
            <div className="expirationDate">
                <label className='selFontTotals' htmlFor="years">Fecha:</label>
                <Select 
                    className='selFontTotals'
                    value={{value: dateSelected, label: dateSelected.month ? dateSelected.month + '/' + dateSelected.year : 'Seleccione una fecha'}} 
                    options={dateOptions()} 
                    onChange={(option) => onChangeDate(option)} 
                />
            </div>
            <TableContainer component={Paper} className="cannonScroll">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Estado</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="cannonTableBody">
                        {renderExamList()}
                    </TableBody>  
                </Table>  
            </TableContainer>
        </>
     );
}
 
export default ExamBBListTable;