import React, {useReducer, useCallback} from 'react';
import BeltsContext from './BeltsContext';
import BeltsReducer from './BeltsReducer';
import { SET_BELTS, SET_INSTRUCTOR_RANKS } from '../../types';

const BeltsState = (props) => {

    const initialState = {
        belts: [],
        instructorRanks: []
    }

    const [state, dispatch] = useReducer(BeltsReducer, initialState);

    const setBelts = useCallback((belts) => {
        dispatch({
            type: SET_BELTS,
            payload: belts
        });
    },[]);

    const setInstructorRanks = useCallback((instructorRanks) => {
        dispatch({
            type: SET_INSTRUCTOR_RANKS,
            payload: instructorRanks
        });
    },[]);

    return ( 
        <BeltsContext.Provider
            value={{
                belts: state.belts,
                instructorRanks: state.instructorRanks,
                setBelts,
                setInstructorRanks
            }}
        >
            {props.children}
        </BeltsContext.Provider>
     );
}
 
export default BeltsState;